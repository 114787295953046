import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";

import bugsnag from 'js/bugsnag';

const ActionButton = styled.a.attrs({ className: 'btn btn--large' })`
  text-decoration: none;
  background-color: rgb(0, 110, 197) !important;
  color: white !important;
`;

const ErrorPage = ({ code }) => {
  return (
    <div className="null-state">
      <div className="null-state-container">
        <div id="graphic" className={code ? `null-state__graphic null-state__graphic-${code}` : "null-state__graphic null-state__graphic-404"}></div>
        <div className="null-state__content">
          <h3 className="null-state__header" style={{ "margin-bottom": "30px" }}>Sorry, we can't seem to find what you're looking for.</h3>
          <Link to="/"><ActionButton>Return Home</ActionButton></Link>
        </div>
      </div>
    </div>
  );
};

const withErrorBoundary = (Component) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        error: undefined
      };
    }

    componentDidCatch(error, info) {
      if (bugsnag) {
        const BugsnagErrorBoundary = bugsnag.getPlugin('react');

        BugsnagErrorBoundary.prototype.componentDidCatch.call(this, error, info);
      }

      this.setState({ error });
    }

    render() {
      if (this.state.error) {
        return <ErrorPage code={this.state.error.code} />;
      }

      return <Component {...this.props} />;
    }
  };
};

export { withErrorBoundary };

export default ErrorPage;
