import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';


const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <section className="bottom">
        <ul className="footer__legal" style={{ "justifyContent": "space-between" }}>
          <section className="flex">
            <li className="footer__legal-item">© Rapid7 {dayjs().year()}</li>

            <li className="footer__legal-item">
              <a className="footer__legal-link" title="Privacy Policy" href="https://www.rapid7.com/privacy-policy">{t('Privacy')}</a>
            </li>

            <li className="footer__legal-item">
              <a className="footer__legal-link" title="Terms & Conditions" href="https://www.rapid7.com/legal">{t('Terms & Conditions')}</a>
            </li>
          </section>
          <section className="flex">
            <li className="footer__legal-item">
              <a className="footer__legal-link" title="Contact Us" href="https://www.rapid7.com/contact">{t('Contact Us')}</a>
            </li>
          </section>
        </ul>
      </section>
    </footer>
  );
};

export default Footer;
