import React from 'react';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import { getUser } from 'js/components/UserProvider';

const host = window.location.host;

let releaseStage =
  (host.match(/staging/i) && 'staging') ||
  (host.match(/rapid7\.com$/i) && 'production') ||
  'development';

const user = getUser();

if (user && user.product) {
  releaseStage += '-in-product';
}

const apiKey = process.env.BUGSNAG_API_KEY;
const appVersion = process.env.GIT_REVISION;

export default (() => {
  if (!apiKey) {
    return;
  }

  const client = bugsnag({
    user,
    apiKey,
    appVersion,
    releaseStage
  });

  client.use(bugsnagReact, React);

  return client;
})();
