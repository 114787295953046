import React, {
  useContext
} from 'react';

import { Route, Switch, BrowserRouter } from "react-router-dom";

import NormalNavigation from 'js/components/Navigation';
import HomePage from 'js/pages/Homepage';
import DetailsPage from 'js/pages/DetailsPage';
// import BuildPage from 'js/pages/BuildPage';
// import ContributePage from 'js/pages/ContributePage';
import ErrorPage, { withErrorBoundary } from 'js/pages/ErrorPage';
import WelcomeMessage from 'js/components/extensions/ExtensionWelcomeMessage';
import InProductWrapper from 'js/components/ModalComponent';
import { ProductProvider, ProductContext, ProductRedirect } from 'js/components/ProductProvider';
import { FilteringProvider } from "js/components/filters/FilterPanel";
import { ThemeProvider } from 'styled-components';
import Footer from "js/components/footer";
import { UserProvider } from "js/components/UserProvider";
import { PropertiesProvider } from 'js/components/PropertiesProvider';
import { NotificationArea, NotificationsProvider } from 'js/components/Notifications';
import { TitleProvider } from 'js/components/TitleProvider';
import Pendo from 'js/pendo';

const Routes = (
  <Switch>
    <Route exact path='/' component={withErrorBoundary(HomePage)}/>
    <Route path='/extension/:slugName/:version?' component={withErrorBoundary(DetailsPage)} />
    {/*<Route path='/build' component={BuildPage}/>*/}
    {/*<Route path='/contribute' component={ContributePage}/>*/}

    <Route path="/welcome" component={WelcomeMessage}/>
    <Route path="/product/:token" component={ProductRedirect} />

    <Route component={ErrorPage}/>
  </Switch>
);

const Providers = ({ children }) => (
  <PropertiesProvider>
    <UserProvider>
      <ProductProvider>
        <TitleProvider>
          <NotificationsProvider>
            {children}
          </NotificationsProvider>
        </TitleProvider>
      </ProductProvider>
    </UserProvider>
  </PropertiesProvider>
);

const OutOfProductWrapper = ({ children }) => (
  <NormalNavigation>
    <NotificationArea />

    {children}

    <Footer/>
  </NormalNavigation>
);

const App = ({ children }) => {
  const Wrapper = useContext(ProductContext) ? InProductWrapper : OutOfProductWrapper;

  return (
    <div className="App ui-light">
      <ThemeProvider theme={{}}>
        <BrowserRouter>
          <Pendo />

          <FilteringProvider>
            <Wrapper>
              {children}

              {Routes}
            </Wrapper>
          </FilteringProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default () => (
  <Providers>
    <App />
  </Providers>
);
