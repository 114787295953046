import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Tab, TabList, Tabs, TabSlider } from "rapid7-ui";

const activateTab = (tabLink) => {
  if (tabLink) {
    const { lastChild: tabSlider } = tabLink.closest('ul');

    Object.assign(tabSlider.style, {
      width: `${tabLink.offsetWidth}px`,
      left: `${tabLink.offsetLeft}px`
    });
  }
};

const identity = () => {};

const TabComponent = ({ tabs, children, activeTabIndex, setKey, ...props }) => (
  <Tabs tabType="tabs" {...props}>
    <TabList align="left">
      {tabs.map(({ title }, i) => {
        const active = activeTabIndex === i;

        return (
          <Tab
            key={`${i}-${active ? '' : 'in'}active`}
            title={title}
            onClick={() => setKey(i)}
            active={active}
            tabLinkRef={active ? activateTab : identity}
          />
        );
      })}
      <TabSlider/>
    </TabList>
    {children}
  </Tabs>
);

const StateTabs = forwardRef(({ children, ...props }, ref) => {
  const [key, setKey] = useState(0);

  useImperativeHandle(ref, () => ({
    setKeyTab: setKey
  }));

  return (
    <TabComponent
      {...props}
      activeTabIndex={key}
      setKey={setKey}
    >
      {React.Children.toArray(children)[key]}
    </TabComponent>
  );
});

export {
  StateTabs
};
