import React from 'react';
import { render } from 'react-dom';

import './i18n';
import 'js/bugsnag';
import 'js/googleAnalytics';

import App from './App';

import icon from 'img/favicon.ico';

const createLink = (attrs) => {
  const link = Object.assign(document.createElement('link'), attrs);

  document.head.appendChild(link);

  return new Promise((resolve) => {
    link.addEventListener('load', resolve);
  });
};

createLink({
  rel: 'icon',
  href: icon
});

(async () => {
  await createLink({
    rel: 'stylesheet',
    /* global __webpack_public_path__ */
    href: `${__webpack_public_path__}styles.css`
  });

  render(<App/>, document.querySelector('#app'));
})();
