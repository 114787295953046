import React, {
  Fragment,
  createContext,
  useState,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react';

import ReactDOMServer from 'react-dom/server';

import { Link, withRouter } from "react-router-dom";
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TagCloud, TagItem, Button, ButtonDropDown, ButtonDropDownItem, ButtonIcon, Icon, Breadcrumbs, BreadcrumbItem } from "rapid7-ui";
import { DataLabels, SupportedLabels, IconLabels } from 'js/components/Labels';
import { StateTabs } from "js/components/tabs";
import { UserContext } from "js/components/UserProvider";
import { PropertiesContext } from "js/components/PropertiesProvider";
import { ProductContext, ProductCallbacksContext } from "js/components/ProductProvider";
import BackButton from 'js/components/BackButton';
import { extractSection, omitSection, Markdown } from "js/components/remark";
import RemarkParser from 'remark-parse';
import MarkDownCss from 'github-markdown-css/github-markdown.css';
import backupLogo from 'img/icon-placeholder-image.png';
import ImageCarousel from "js/components/details/ImageCarousel";
import ExternalLink, { useExternalLink } from 'js/components/ExternalLink';
import ForumLink from 'js/components/ForumLink';
import ExtensionDiscourseTopics from 'js/components/extensions/ExtensionDiscourseTopics';
import { NotificationsContext } from 'js/components/Notifications';
import { Title } from 'js/components/TitleProvider';
import { FormattedDate } from 'js/components/FormattedDate';


// const ExtensionContentImage = styled.img`
//   width: 150px;
//   height: 150px;
//   border-radius: 100px;
//   background-color: #FFF;
// `;

// const ExtensionContentImageBottom = styled.img`
//   width: 300px;
//   height: 200px;
//   border: 1px solid gray;
// `;

const ImageStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 125px;
  width: 125px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  
  @media screen and (max-width: 480px){
    display: none;
  }
  
  @media screen and (min-width: 480px){
    display: flex;
  }
`;

const ExpandableDiv = styled.div`
  padding: 0 20px;
  max-height: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s max-height ease-out;
  background-color: #F4F6F8;
  &.show {
    max-height: 100%;
    padding: 0 20px 30px 20px;
   }
`;

const ExpandableDivNormal = styled.div`
  max-height: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s max-height ease-out;
  &.show {
    max-height: 100%;
   }
`;

const ExpandableFilterDiv = styled.div`
  height: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s max-height ease-out;
  
  &.show {
    // display: flex;
    // max-height: 100%;
    // min-height: 50px;
    
    max-height: 100%;
    min-height: 150px;
    overflow-y: scroll;
    background-color: white !important;
   }
`;

const VersionEntriesStyling = styled.div`
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
  }

  b {
    padding-right: 5px;
    font-size: 16px;
    font-weight: 900;
  }

  .date {
    color: #82939C;
    font-size: 12px;
    font-weight: 500;
    padding-left: 5px;
  }

  .current {
    color: #1CBB98;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 900;
  }

  hr {
    border: 0.5px solid #CCCCCC !important;
  }
`;

const SectionSpacing = styled.div`
  section {
    margin: 0 0 20px 0;
  }

  .headerRemoval h1:first-of-type {
    display: none;
  }
`;

const ExpandableDivButton = styled.div`
  button:focus {
    outline:0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BreadcrumbsStyle = styled.div`
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 1330px;
  width: 90%;
  margin: 10px 0 20px 0;
`;

const BreadcrumbItemStyle = styled(BreadcrumbItem)`
  font-family: Muli;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.5px;

  :first-child span a {
    color: #3B454A;
    text-decoration: none;
  }

  span a {
    color: #E95F26;
    text-decoration: none;
  }

  span ::before {
    color: black !important;
  }
`;

const DetailsH4 = styled.h4`
  font-size: 14px;
  padding-top: 10px;
  font-weight: 900;
`;

const ToggleButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100% !important;

  .h3 {
    padding-left: initial;
  }

  i {
    align-self: center;
    font-size: 20px;
  }
`;

const ToggleFilterButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 35px !important;
  padding: 0 15px !important;

  .h3 {
    padding-left: initial;
  }

  i {
    align-self: center;
    font-size: 20px;
  }
`;

const ExtensionTitle = styled.div`
  font-size: 32px;
  font-weight: 900;
  color: #434343;
  margin-bottom: -8px;
  line-height: 34px;
  
  @media screen and (max-width: 950px){
    font-size: 22px;
  }
`;

const ExtensionVendorTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #82939C;
  letter-spacing: 0.5px;
  padding: 10px 0 5px 0;
`;

const StyledTagItem = styled(TagItem)`
  border-radius: 3px;
  background-color: #82939C;
  border: none !important;
  color: white !important;
  font-size: 12px !important;
`;

const StyledOverview = styled.div`

  p {
    font-size: 14px;
    line-height: 26px;
  }

  ul {
    margin: 0 !important;
    padding-left: 20px !important;

    li {
      padding: 5px;
    }
  }

`;

const StyledTabs = styled.div`

  ul.tab-header__list {
    display: flex;
    justify-content: space-between;
  }

  li.tab-header__item {
    margin-bottom: 5px !important;

    a {
      width: 200px;
    }
  }

  .tab-header__link {
    font-size: 22px !important;
    font-weight: 900 !important;
  }

  .tab-header__item--slide {
    width: 150px !important;
    left: 30px !important;
  }
`;

const VendorSupport = styled.h4`
  fontSize: 12px;
  font-weight: 600;
  padding-top: 5px;

  a {
    color: #006EC5;
    text-decoration: none;
  }
`;

const StyledDetailsHeader = styled.div`
  background-color: aliceblue;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  display: flex;
  width: 100%;
  padding: 0px;

  .detailsBack {
    height: 100%;
    font-size: 18px;
    align-items: center;

    @media (max-width: 1100px){
      font-size:14px;
    }
  }
  
  @media screen and (max-width: 768px){
    display: none;
  }
`;

const ImageStylesHeader = styled.div`
  height: 60px;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: flex;
  align-items: center;
`;

const ExtensionTitleHeader = styled.div`
  align-items: center;
  height: 100%;
  font-size: 26px;
  font-weight: 900;
  color: #434343;
  margin-bottom: -8px;
  line-height: 34px;

  @media (max-width: 1100px){
    font-size:20px;
    line-height: 20px;
  }
`;

const StyledTagCloud = styled(TagCloud)`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledTagLink = styled(Link)`
  text-decoration: none;
  margin-right: 5px;
  margin-bottom: 5px;
  
  li {
    padding: 5px;
    whitespace: nowrap;
  }
`;

const StyledDiscourse = styled.div`
  a {
    position: absolute;
    right: 50px;
    margin-top: -55px;
  }

   i {
      display: none;
    }
`;

const SearchBarStickyWrapper = styled.section`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 25px;
  z-index: 5;
  background: aliceblue;
  justify-content: center;
  display: flex;
  box-shadow: 0 0 5px 0 white;

  @keyframes enter {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }
`;

const useToggle = () => useReducer((value) => !value, false);

const Expandable = ({ onDownloadPDF, children, title, download, documentation }) => {
  const [expanded, toggle] = useToggle();
  const { t } = useTranslation();

  return (
    <Fragment>
      <ExpandableDivButton
        style={documentation ? { "backgroundColor": "#F4F6F8" } : { "backgroundColor": "none" }}
      >
        {!documentation && (<h3 style={documentation ? { "paddingLeft": "10px" } : { "": "" } } >{title}</h3>)}
        {documentation && (
          <div className="flex" style={{ "width": "100%", "height": "50px" }}>
            <ToggleButton onClick={toggle}>
              <h3 style={documentation ? { "paddingLeft": "10px", "fontSize": "22px", "fontWeight": "900" } : { "": "" } } >{title}</h3>
              <Icon icon={expanded ? "arrow-chevron-up" : "arrow-chevron-down"}/>
            </ToggleButton>
          </div>
        )}
      </ExpandableDivButton>
      {documentation && (
        <ExpandableDiv className={expanded ? "show" : ""}>
          {download ?
            <div style={{ "paddingTop": "20px" }}>
              <button className="btn btn--small" style={{ "color": "#006EC5", "cursor": "cursor" }} onClick={onDownloadPDF}>Print / Download </button>
            </div>
            : ''}
          {children}
        </ExpandableDiv>
      )}
      {!documentation && (
        <Fragment>
          <ExpandableDivNormal className={!expanded ? "show" : ""}>
            {children}
          </ExpandableDivNormal>
          <button style={{ "color": "#519BC8" }} onClick={toggle}>
            {!expanded ? `${t('Show Less')}` : `${t('Show More')}`}
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

const ExpandableFilter = ({ children, title }) => {
  const [expanded, toggle] = useToggle();
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="flex" style={{ "width": "100%" }}>
        <ToggleFilterButton onClick={toggle}>
          <h3 className="card__title" style={{ "fontSize": "16px", "fontWeight": "600" }}>{title}</h3>
          <Icon icon={!expanded ? "arrow-chevron-down" : "arrow-chevron-up"}/>
        </ToggleFilterButton>
      </div>
      <ExpandableFilterDiv className={expanded ? "show" : ""}>
        {children}
      </ExpandableFilterDiv>
    </Fragment>
  );
};

const ExtensionDetailLabels = ({ value }) => {
  return (
    <StyledTagCloud className="flex middle-xs left-md" size="medium">
      {value.map((item, i) =>
        <StyledTagLink to={`/?tags=${item.displayName}`}>
          <StyledTagItem key={i} type="system">
            {item.displayName}
          </StyledTagItem>
        </StyledTagLink>
      )}
    </StyledTagCloud>
  );
};

const VersionEntries = ({ version, updated, current }) => {
  const { t } = useTranslation();

  return (
    <VersionEntriesStyling>
      <li>
        <div className="flex">
          <b>v{version} - </b>
          <FormattedDate date={updated} />
        </div>
        <p className="current">{current ? `${t('CURRENT')}` : ''}</p>
      </li>
      <hr/>
    </VersionEntriesStyling>
  );
};
const useDropdown = () => useReducer((value) => !value, false);

const VersionButton = styled(Button).attrs({
  context: '',
  isDropDown: true
})`
  background-color: white;
  border: 1px solid #C8C8C8 !important;
`;

const ActionButtonParent = styled(Button).attrs({
  isDropDown: true,
  size: 'large',
  context: 'primary'
})``;

const VersionButtonDropDown = styled(ButtonDropDown)`
  min-width: 100% !important;
  left: -1px;
  right: -1px;
`;

const ButtonDropDownIcon = styled(ButtonIcon).attrs({
  icon: 'drop-chev'
})``;

const ButtonFeedbackIcon = styled(ButtonIcon)`
  transform: none !important;
`;

const VersionButtonIcon = styled(ButtonDropDownIcon)`
  margin-top: 2px !important;
`;

const VersionButtonCurrentVersion = styled.div`
  justify-content: space-around;
  display: flex;
`;

const VersionButtonCurrentVersionNumber = styled.span`
  color: #3B454A;
  font-weight: 400;
`;

const VersionSelector = ({ extension }) => {
  const [isActive, toggle] = useDropdown();

  const items = useMemo(() => {
    return orderBy(extension.versions, ['date'], ['desc'])
      .map(({ version }) => (
        <ButtonDropDownItem context="tertiary" key={version}>
          <Link to={`/extension/${extension.slugName}/v${version}`}>Version {version}</Link>
        </ButtonDropDownItem>
      ));
  }, [extension.versions]);

  return (
    <VersionButton
      isActive={isActive}
      onClick={toggle}
    >
      <VersionButtonCurrentVersion>
        <VersionButtonCurrentVersionNumber>
          Version {extension.version}
        </VersionButtonCurrentVersionNumber>

        <VersionButtonIcon />
      </VersionButtonCurrentVersion>

      <VersionButtonDropDown isActive={isActive}>
        {items}
      </VersionButtonDropDown>
    </VersionButton>
  );
};

const feedbackIcons = {
  pending: 'in-progress-loading-circle',
  resolved: 'remediation',
  rejected: 'exclamation-caution'
};

const ActionStyledButton = styled.a.attrs({ className: 'btn btn--primary btn--large' })`
  text-decoration: none;

  ${VersionButton} + & {
    margin-top: 10px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
`;

const ActionButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  
  @media (max-width: 1100px){
    height: 50px;
    margin-top: 10px;
  }
`;

const ActionButtonContainerinProduct = styled.section`
  height: 55px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: left;

  > div {
    margin: 10px 20px 0 20px;
  }
  
  @media screen and (max-width: 768px){
    display: none;
  }
`;

const ActionButtonDropDownItem = styled(ButtonDropDownItem)`
  text-decoration: none;
`;

const needsLogin = (extensionType) => {
  switch (extensionType) {
  case 'in-product':
  case 'in_product':
    return true;
  case 'integration_listing':
    return false;
  case 'integration_package':
    return false;
  case 'workflow':
    return true;
  case 'plugin':
    return true;
  }

  return false;
};

const toVersionNumber = (version) => {
  return version
    .split('.')
    .map((n) => parseInt(n, 10))
    .reduceRight((version, n, i, { length }) => {
      return version + n * Math.pow(1000, length - i);
    }, 0);
};

const compareVersions = (a, b) => {
  const delta = toVersionNumber(a) - toVersionNumber(b);

  // > 0 : a is newer
  // = 0 : same version
  // < 0 : a is older
  return Math.min(Math.max(-1, delta), 1);
};

const getPluginStatus = (organization, extension) => {
  const { activations = [] } = extension;

  if (activations.length) {
    const versionsInOrg = activations.filter(({ orgId }) => orgId === organization.id).map(({ version }) => version);

    if (versionsInOrg.includes(extension.version)) {
      return 'installed';
    }

    const outdated =
      versionsInOrg.length > 0 &&
      versionsInOrg.every((version) => compareVersions(version, extension.version) < 0);

    if (outdated) {
      return 'outdated';
    }
  }
};

const ActionButtonVerb = ({ extension, status }) => {
  const { t } = useTranslation();

  switch (extension.type) {
  case 'in-product':
  case 'in_product':
    return t('Configure');
  case 'integration_listing':
    return t('View Integration');
  case 'integration_package':
    return t('Download');
  case 'workflow':
    return t('Import');
  }

  switch (status) {
  case 'installed':
    return (
      <React.Fragment>
        <Icon icon="remediation" />

        {' '}
        {t('Installed')}
      </React.Fragment>
    );
  case 'outdated':
    return t('Update');
  }

  return t('Install');
};

const successNotification = {
  workflow({ name }) {
    return {
      content: `Successfully imported draft workflow of "${name}"`,
      actionText: 'Edit Workflow'
    };
  },
  plugin({ name, version }) {
    return {
      content: `Successfully installed "${name}" version ${version}`,
      actionText: 'View Plugins'
    };
  }
};

const ButtonFeedbackContext = createContext();

const useFeedback = () => {
  const [status, setStatus] = useState();

  const withFeedback = useCallback((action) => {
    return async (...args) => {
      setStatus('pending');

      try {
        await action(...args);
      }
      catch (e) {
        setStatus('rejected');

        throw e;
      }

      setStatus('resolved');
    };
  }, []);

  const feedbackIcon = feedbackIcons[status];

  return [
    feedbackIcon && (
      <React.Fragment>
        <ButtonFeedbackIcon icon={feedbackIcon} className={status === 'pending' && 'is-loading'} />

        {' '}
      </React.Fragment>
    ),
    withFeedback
  ];
};

const ActionButton = ({
  extension,
  product,
  includeProduct = false,
  component: Button = ActionStyledButton,
  children
}) => {
  const {
    type,
    actionHref
  } = extension;

  const status = getPluginStatus(product.organization, extension);

  const currentProduct = useContext(ProductContext);
  const productCallbacks = useContext(ProductCallbacksContext);

  const navigateProduct = useCallback((product, href) => {
    if (currentProduct && product && currentProduct.code === product.code && currentProduct.token === product.token) {
      // Already in the in-product view for this organization-product combination, so can route internally
      return productCallbacks.navigateProduct(href);
    }

    // Build the url to navigate to the product externally
    const url = new URL(product.url);

    if (!href.startsWith('/')) {
      href = `/${href}`;
    }

    // Assuming all product use hash-based routing
    url.hash = href;

    productCallbacks.navigateExternal(`${url}`);
  }, [
    product,
    currentProduct,
    productCallbacks
  ]);

  const { notify } = useContext(NotificationsContext);

  const activate = useCallback(async () => {
    try {
      const response = await fetch(actionHref, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
          organizationToken: product.token
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status < 200 || response.status >= 400) {
        throw new Error('Invalid response');
      }

      const result = await response.json();

      const { href = '/settings/plugins' } = result || {};

      const buildNotification = successNotification[type];

      notify({
        ...(buildNotification && buildNotification(extension)),
        id: `${+new Date()}-success`,
        status: 'successful',
        action: () => navigateProduct(product, href)
      });
    }
    catch (e) {
      notify({
        id: `${+new Date()}-failure`,
        status: 'critical',
        content: 'There was an issue performing this action'
      });

      throw e;
    }
  }, [
    product,
    navigateProduct
  ]);

  let buttonProps = {
    onClick: useExternalLink(),
    children: (
      <React.Fragment>
        <ActionButtonVerb
          extension={extension}
          status={status}
        />

        {includeProduct && (
          <React.Fragment>
            {' in '}
            {product.name} - {product.organization.displayName} ({`${product.organization.regionCode}`.toUpperCase()})
          </React.Fragment>
        )}
      </React.Fragment>
    )
  };

  switch (type) {
  case 'in-product':
  case 'in_product':
    buttonProps.onClick = () => navigateProduct(product, actionHref);
    break;
  case 'integration_listing':
    // External redirect to 3rd-party site
    buttonProps.href = actionHref;
    buttonProps.target = '_blank';
    break;
  case 'integration_package':
    buttonProps.href = actionHref;
    buttonProps.download = 'download';
    break;
  case 'workflow':
    buttonProps.onClick = activate;
    break;
  case 'plugin':
    buttonProps.onClick = activate;
    break;
  default:
    buttonProps = null;
  }

  if (status === 'installed') {
    buttonProps.className = 'is-disabled';
  }

  const withFeedback = useContext(ButtonFeedbackContext);

  const onClick = useMemo(() => {
    if (buttonProps && buttonProps.onClick) {
      return withFeedback(buttonProps.onClick);
    }
  }, [buttonProps && buttonProps.onClick]);

  return (
    <Button {...buttonProps} onClick={onClick}>
      {children}
      {buttonProps.children}
    </Button>
  );
};

const ActionButtonDropdown = withRouter(({ location, extension }) => {
  const { t } = useTranslation();

  const currentUser = useContext(UserContext);
  const currentProduct = useContext(ProductContext);

  const {
    extensionProductCodes,
    applicableProducts
  } = useMemo(() => {
    const productPredicate =
      // Some marked as both IDR & ICON, but no workflow or plugin is installable in a non-icon product
      (extension.type === 'workflow' || extension.type === 'plugin') ?
        ((code) => code === 'ICON') :
        (() => true);

    const extensionProductCodes = extension.products
      .map((product) => product.registryCode)
      .filter(productPredicate);

    const applicableProducts = currentUser ?
      currentUser.products.filter((product) => {
        return extensionProductCodes.some((code) => product.code.startsWith(code));
      }) : [];

    return {
      extensionProductCodes,
      applicableProducts
    };
  }, [
    currentUser,
    extension.products
  ]);

  const activationButtons = useMemo(() => {
    if (!currentUser) {
      return [];
    }

    return applicableProducts.map((product) => (
      <ActionButton
        key={product.token}
        extension={extension}
        product={product}
      />
    ));
  }, [
    currentUser && currentUser.products,
    extension.products
  ]);

  const [isActive, toggle] = useDropdown();

  const [feedback, withFeedback] = useFeedback();

  const login = useExternalLink();

  if (needsLogin(extension.type) && !currentUser) {
    return (
      <ActionStyledButton
        href={`/login?redirectPath=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
        onClick={login}
      >
        {t('Sign in to')} <ActionButtonVerb extension={extension} />
      </ActionStyledButton>
    );
  }

  // A user not in-product who doesn't belong to a customer in the open preview must request access
  if (currentUser && !currentProduct && 'whitelisted' in currentUser && !currentUser.whitelisted) {
    return (
      <ActionStyledButton target="_blank" href="mailto:integrationalliance@rapid7.com">
        {t('Request Preview Access')}
      </ActionStyledButton>
    );
  }

  if (extension.type === "integration_package") {
    return (
      <ActionStyledButton>
        <a target='__blank' href={extension.actionHref}>
          <ActionButtonVerb extension={extension} />
        </a>
      </ActionStyledButton>
    );
  }

  if (activationButtons.length === 0) {
    if (currentUser && extensionProductCodes.includes('ICON')) {
      return (
        <ActionStyledButton target="_blank" href="https://www.rapid7.com/info/insightconnect-contact/">
          {t('Request InsightConnect Demo')}
        </ActionStyledButton>
      );
    }

    return (
      <ActionStyledButton target="_blank" href="https://www.rapid7.com/try/insight/?lv=2">
        {t('Start A New Trial')}
      </ActionStyledButton>
    );
  }
  else if (activationButtons.length === 1) {
    const [button] = activationButtons;

    return (
      <ButtonFeedbackContext.Provider value={withFeedback}>
        {React.cloneElement(button, button.props, (
          <React.Fragment>
            {feedback}

            {button.children}
          </React.Fragment>
        ))}
      </ButtonFeedbackContext.Provider>
    );
  }

  return (
    <ActionButtonParent
      isActive={isActive}
      onClick={toggle}
    >
      {feedback}

      <ActionButtonVerb extension={extension} />

      <ButtonDropDownIcon />

      <ButtonFeedbackContext.Provider value={withFeedback}>
        <ButtonDropDown isActive={isActive}>
          {activationButtons.map((button) => {
            return React.cloneElement(button, { component: ActionButtonDropDownItem, includeProduct: true });
          })}
        </ButtonDropDown>
      </ButtonFeedbackContext.Provider>
    </ActionButtonParent>
  );
});

const ExtensionDetail = (props) => {
  const { t } = useTranslation();

  const {
    type,
    icon,
    name,
    masterId,
    tags,
    version,
    versions,
    supportedBy,
    products,
    markdown,
    slugName,
    helpDocumentation,
    vendor,
    resources
  } = props;

  const currentProduct = useContext(ProductContext);
  const properties = useContext(PropertiesContext);

  const markdownAst = new RemarkParser.Parser('', markdown).parse();

  const onDownloadPDF = useCallback(() => {
    const frame = document.createElement('iframe');
    const body = document.createElement('body');
    const style = document.createElement('style');

    style.innerHTML = `${MarkDownCss}`;
    body.innerHTML = ReactDOMServer.renderToString(<Markdown markdown={omitSection(markdownAst, ['Version History'])} />);
    body.className = 'markdown-body';
    body.appendChild(style);

    Object.assign(frame.style, {
      width: '0px',
      height: '0px',
      position: 'absolute',
      top: '0px',
      left: '0px'
    });

    frame.sandbox = 'allow-same-origin allow-modals';
    frame.srcdoc = body.outerHTML;

    const { title } = document;

    document.title = `${name} Documentation V${version}`;
    document.body.appendChild(frame);

    frame.contentWindow.print();

    const afterPrint = () => {
      document.title = title;
      document.body.removeChild(frame);

      window.removeEventListener('focus', afterPrint);
    };

    window.addEventListener('focus', afterPrint);
  }, [markdownAst]);

  //Sticky Details Header
  const headerRef = useRef();
  const contentRef = useRef();
  const stickyDetailsHeader = useRef();

  const content = document.querySelector(currentProduct ? '.inproduct-content' : '.app-content');

  const onScroll = useCallback(() => {
    stickyDetailsHeader.current.classList.toggle('show', content.scrollTop > headerRef.current.offsetHeight);
  }, [content]);

  useEffect(() => {
    if (content) {
      content.addEventListener('scroll', onScroll);

      return () => content.removeEventListener('scroll', onScroll);
    }
  }, [content]);

  return (
    <Fragment>
      {name && <Title>{name}</Title>}

      <section className="header-block" style={{ "backgroundColor": "aliceblue" }}>
        {!currentProduct && (
          <BreadcrumbsStyle>
            <Breadcrumbs>
              <BreadcrumbItemStyle>
                <BackButton text={t('Rapid7 Extension Home')}/>
              </BreadcrumbItemStyle>
              <BreadcrumbItemStyle>
                <Link to={`/extension/${slugName}`}>
                  {name}
                </Link>
              </BreadcrumbItemStyle>
            </Breadcrumbs>
          </BreadcrumbsStyle>
        )}
        <section className="base">
          {currentProduct && (
            <BackButton className="detailsBack"/>
          )}
          <div className="row">
            <div className="col-xs-12 col-md-3 col-lg-2" ref={headerRef}>
              <div className="ImageDetailsContainer">
                <ImageStyles>
                  <img alt="Image Unavailable" style={{ "maxHeight": "100%" }} onError={(e) => {
                    e.target.src = `${backupLogo}`;
                  }} src={icon}/>
                </ImageStyles>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-7">
              <div className="details-header">
                <section className="flex">
                  { type && <DataLabels value={type}/>}
                  { products && <IconLabels product={true} details={true} value={products}/>}
                </section>
                <ExtensionTitle className="card__product-heading">{name}</ExtensionTitle>
                <ExtensionVendorTitle className="card__heading">
                  {vendor ? vendor.toUpperCase() : 'Developer'}
                </ExtensionVendorTitle>
                {tags &&
                  <div className="flex middle-xs left-md">
                    <ExtensionDetailLabels value={tags}/>
                  </div>
                }
              </div>
            </div>
            <div className="col-xs-12 col-md-3 col-lg-3">
              <ActionButtonContainer>
                {versions && versions.length > 1 && type !== 'in_product' && <VersionSelector extension={props} />}
                {products && <ActionButtonDropdown extension={props} />}
              </ActionButtonContainer>
            </div>
          </div>
        </section>
      </section>

      <StyledDetailsHeader ref={stickyDetailsHeader} className={currentProduct ? 'sticky-details' : 'sticky-details'}>
        <div className="col-xs-1 col-md-1 col-lg-1">
          <BackButton className="detailsBack" />
        </div>
        <div className="col-xs-2 col-md-2 col-lg-1">
          <ImageStylesHeader>
            <img alt="Image Unavailable" style={{ "maxHeight": "100%" }} onError={(e) => {
              e.target.src = `${backupLogo}`;
            }} src={icon}/>
          </ImageStylesHeader>
        </div>
        <div className="col-xs-8 col-md-6 col-lg-6">
          <ExtensionTitleHeader className="card__product-heading flex">{name}</ExtensionTitleHeader>
        </div>
        <div className="col-xs-0 col-md-3 col-lg-6">
          <ActionButtonContainerinProduct>
            {versions && versions.length > 1 && type !== 'in_product' && <VersionSelector extension={props} />}
            {products && <ActionButtonDropdown extension={props} />}
          </ActionButtonContainerinProduct>
        </div>
      </StyledDetailsHeader>

      {/*Main Section*/}
      <section className="content-block">
        <div ref={contentRef}>
          <section className="base">
            <div className="row">
              <div className="col-xs-12 col-lg-9">
                <SectionSpacing className="row">
                  <section className="col-xs-12 col-lg-7">
                    <StyledOverview>
                      <h3 className="card__product-heading" style={{ "fontSize": "22px", "fontWeight": "900" }}>{t('Overview')}</h3>
                      <Markdown markdown={extractSection(markdownAst, "Description")}/>
                      <h3 className="card__product-heading, pPadding" style={{ "fontSize": "14px", "fontWeight": "900" }}>{t('KeyFeatures')}</h3>
                      <Markdown markdown={extractSection(markdownAst, "Key Features")}/>
                      <h3 className="card__product-heading" style={{ "fontSize": "14px", "fontWeight": "900" }}>{t('Requirements')}</h3>
                      <Markdown markdown={extractSection(markdownAst, "Requirements")}/>
                    </StyledOverview>
                  </section>
                  <section className="col-xs-12 col-lg-5">
                    <div className="flex middle-xs">
                      {resources && resources.screenshots !== [] &&
                        <ImageCarousel
                          slugName={slugName}
                          type={type}
                          version={version}
                          screenshots={resources.screenshots}
                        />
                      }
                    </div>
                  </section>
                  <section className="col-xs-12 col-lg-12">
                    <Expandable
                      className="expandable"
                      title={t('Documentation')}
                      download={true}
                      documentation={true}
                      id={masterId}
                      md={helpDocumentation}
                      onDownloadPDF={onDownloadPDF}
                      style={{ "paddingLeft": "10px" }}
                    >
                      <div id="help-documentation" className="markdown-body headerRemoval">
                        <Markdown markdown={omitSection(markdownAst, ['Description', 'Key Features', 'Requirements', 'Version History'])}/>
                      </div>
                    </Expandable>
                  </section>
                  <div className="col-xs-12 col-lg-12">
                    <StyledTabs>
                      <StateTabs
                        tabs={[
                          // { title: 'Reviews' },
                          { title: 'Discussion' }
                        ]}
                      >
                        {slugName && <ExtensionDiscourseTopics slug={slugName} />}
                      </StateTabs>
                    </StyledTabs>
                  </div>
                </SectionSpacing>
              </div>

              <div className="col-xs-12 col-lg-3">
                <h3 style={{ "fontWeight": "900", "fontSize": "22px", "marginRight": "10px" }} className="card__product-heading">
                  <span style={{ "marginRight": "10px" }}>{t('Support')}</span>
                  {supportedBy && <SupportedLabels value={`${supportedBy} ${t('supported')}`}/>}
                </h3>
                {supportedBy === 'rapid7' ? (
                  <Fragment>
                    <DetailsH4>Rapid7 Support</DetailsH4>
                    <p className="pDetails">
                      This extension is contributed by Rapid7. To report a bug or request enhancements, please <ExternalLink href="https://r7support.force.com/">visit our Customer Portal.</ExternalLink>
                    </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <DetailsH4>Community Support</DetailsH4>
                    <p className="pDetails">For best practices and common extension issues, <ForumLink>check out the discussion forum</ForumLink></p>
                  </Fragment>
                )}
                <DetailsH4>General Feedback</DetailsH4>
                <p className="pDetails">
                  Have general feedback about the extension experience or ideas to
                  add new features to current extensions?
                  Please post or comment in our <ForumLink>Discussion Forum</ForumLink>
                </p>

                <DetailsH4>Vendor Resources</DetailsH4>

                {type !== 'in_product' && (
                  <VendorSupport>
                    {resources && resources.licenceUrl && (
                      <ExternalLink href={resources.licenceUrl}>Extension License</ExternalLink>
                    )}
                  </VendorSupport>
                )}

                {type !== 'in_product' && (
                  <VendorSupport>
                    {resources && resources.sourceUrl && (
                      <ExternalLink href={resources.sourceUrl}>Extension Source</ExternalLink>
                    )}
                  </VendorSupport>
                )}

                <VendorSupport>
                  {resources && resources.vendorUrl && (
                    <ExternalLink href={resources.vendorUrl}>
                      Vendor Website
                    </ExternalLink>
                  )}
                </VendorSupport>

                <h3 style={{ "fontWeight": "900", "fontSize": "22px", "marginTop": "20px" }} className="card__product-heading">{t('Version History')}</h3>

                {type !== 'in_product' ? (
                  <ul style={{ "paddingInlineStart": "0" }}>
                    {orderBy(versions, ['updated'], ['desc']).map((content, index) =>
                      <VersionEntries key={index} current={index === 0} {...content}/>
                    )}
                  </ul>
                ) : <p className="pDetails">This extension does not have any version history associated with it</p>}
                {/*<h3 className="card__product-heading">Blogs</h3>*/}
                {/*<DetailsH4>Blog Title 1</DetailsH4>*/}
                {/*<p className="pDetails">Lorem ipsum dolor sit amet, consectetur adipisng elit, sed do eiusmod.</p>*/}
                {/*<DetailsH4>Blog Title 2</DetailsH4>*/}
                {/*<p className="pDetails">Lorem ipsum dolor sit amet, consectetur adipisng elit, sed do eiusmod.</p>*/}
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fragment>
  );
};

export {
  ExtensionDetail,
  ExpandableFilter
};
