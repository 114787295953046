import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { FilteringContext } from "./FilterPanel";
import { Icon, TagCloud, TagItem } from "rapid7-ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SearchBarContainer = styled.section`
  margin: auto;
  max-width: 1200px;
  width: 90%;
  background: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 100px;
  padding: 0px 20px;
  box-shadow: 0px 0px 30px rgba(#000, 0.15);
  border: 1px solid lightgrey;
  overflow-y:hidden;

  i.search {
    font-size: 20px;
    margin-right: 10px;
    color: black !important;
  }

  input {
    width: 90%;
    border: 0;
    outline: none;
    color: #3B454A;
    font-size: 16px;
  }
`;

const SearchBarStickyWrapper = styled.section`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 5;
  background: white;
  animation: enter .3s;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  @keyframes enter {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }
`;

const StyledTagItem = styled(TagItem)`
  border-radius: 3px; 
  background-color: #EBF8FF;
  border: 1px solid #5797B7 !important;
  color: #5797b7 !important;
  padding: 3px !important;
  display: flex !important;
  
  i.tags {
    font-size: 18px;
  }
  
  span {
    padding: 0 5px;
    border-radius: 10px;
    font-size: 12px;
    white-space: nowrap;
  }
  
  a {
     text-decoration: none;
     color: black;
     font-size: 12px;
     padding-top: 2px;
     padding-left: 3px;
  }
`;

const SearchBarTag = ({ tag }) => {
  const {
    onChangeFilter
  } = useContext(FilteringContext);

  const remove = useCallback(() => {
    onChangeFilter('tags', tag);
  }, [
    tag,
    onChangeFilter
  ]);

  return (
    <StyledTagItem className="tag-cloud__item tag-cloud__item--system">
      <span>{tag}</span>
      <button style={{ "cursor": "pointer" }} onClick={remove}><Icon icon="delete-x"/></button>
    </StyledTagItem>
  );
};

const SearchBar = React.forwardRef(({ sticky, className }, ref) => {
  const {
    parameters,
    onChangeFilter
  } = useContext(FilteringContext);

  const { t } = useTranslation();
  const textInputRef = useRef();

  useEffect(() => {
    textInputRef.current.value = parameters.query || '';
  }, [
    parameters.query
  ]);

  const debouncedOnChange = useMemo(() => {
    const change = debounce((query) => onChangeFilter('query', query), 500);

    return ({ target }) => change(target.value);
  }, [onChangeFilter]);

  const Wrapper = sticky ? SearchBarStickyWrapper : React.Fragment;

  const Tags = parameters.tags ? [...parameters.tags] : [];

  return (
    <Wrapper ref={sticky ? ref : null}>
      <SearchBarContainer className={className} ref={sticky ? null : ref }>
        <Icon className="search" icon="search"/>
        {Tags.slice(0, 4).map((tag) => <TagCloud size="large"><SearchBarTag tag={tag} key={tag} /></TagCloud>)}
        {Tags.length > 5 && <StyledTagItem className="tag-cloud__item tag-cloud__item--system">...</StyledTagItem>}
        <input ref={textInputRef} type="text" placeholder={t("SearchPlaceholder")} onChange={debouncedOnChange} />
        {parameters.query || parameters.tags ? <Link to="/"><Icon style={{ "textDecoration": "none" }} icon="delete-x"/></Link> : ''}
      </SearchBarContainer>
    </Wrapper>
  );
});

const InProductSearchBar = styled(SearchBar)`
  width: 50%;
  margin: 0 30px;
`;

const DetailsSearchBar = styled(SearchBar)``;

// const NavBarSearchBar = styled(SearchBar)`
//   width: 55%;
//   height: 40px;
//
//   background-color: white;
//
//   i.search {
//     color: black !important;
//   }
//
//   input {
//     background-color:white;
//
//     ::placeholder {
//       color: black;
//     }
//   }
// `;

export { InProductSearchBar, DetailsSearchBar };

export default SearchBar;
