import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback
} from 'react';

import styled from 'styled-components';

import {
  Button,
  Notifications,
  NotificationItem,
  NotificationHeader
} from 'rapid7-ui';

import ExternalLink from 'js/components/ExternalLink';

const NotificationButton = styled(Button).attrs({
  context: 'banner'
})`
  margin: 0 15px;
`;

const StyledNotificationHeader = styled(NotificationHeader)`
  @media screen and (max-width: 720px){
    .previewMessage {
      display: none;
    }
  }
`;

const icons = {
  successful: 'r7-icon-remediation',
  critical: 'r7-icon-exclamation-caution'
};

const NotificationsContext = createContext();

const separator = ' - ';

const banner = (
  <Notifications>
    <NotificationItem className="preview">
      <StyledNotificationHeader>
        <b>Open Preview</b>{separator}
        <span className="previewMessage">Our engineers would like to hear your feedback!</span>
        <button className="btn btn--secondary discussion">
          <ExternalLink href="https://discuss.rapid7.com/t/feedback-for-us-from-you/90">
            Help us improve the experience
          </ExternalLink>
        </button>
      </StyledNotificationHeader>
    </NotificationItem>
  </Notifications>
);

const NotificationArea = () => {
  const { notifications } = useContext(NotificationsContext);

  if (notifications.length === 0) {
    return banner;
  }

  return (
    <React.Fragment>
      {banner}

      <Notifications>
        {notifications.map(({ id, status, dismiss, content, action, actionText }) => (
          <NotificationItem key={id} status={status}>
            <NotificationHeader onClear={dismiss} icon={icons[status]}>
              {content}

              {action && (
                <NotificationButton onClick={action}>
                  {actionText}
                </NotificationButton>
              )}
            </NotificationHeader>
          </NotificationItem>
        ))}
      </Notifications>
    </React.Fragment>
  );
};

const NotificationsProvider = ({ children }) => {
  const [notifications, update] = useState([]);

  const notify = useCallback((notification) => {
    const dismiss = () => {
      update((notifications) => notifications.filter(({ id }) => id !== notification.id));
    };

    update((notifications) => [
      {
        ...notification,
        dismiss,
        action: notification.action ? () => {
          dismiss();
          notification.action();
        } : undefined
      },
      ...notifications
    ]);
  }, []);

  return (
    <NotificationsContext.Provider value={useMemo(() => ({ notify, notifications }), [notifications])}>
      {children}
    </NotificationsContext.Provider>
  );
};

export {
  NotificationsProvider,
  NotificationsContext,
  NotificationArea
};
