import React, {
  useContext,
  useRef
} from 'react';

import { Link, withRouter } from 'react-router-dom';
import { UserContext } from "./UserProvider";
import { PropertiesContext } from './PropertiesProvider';

import logo from 'img/rapid7-logo-white-orange.png';

import { Icon } from 'rapid7-ui';

const Navigation = (props) => {
  const user = useContext(UserContext);
  const properties = useContext(PropertiesContext);

  const {
    children,
    location: {
      pathname: path
    }
  } = props;

  const toggleProfileMenu = ({ currentTarget }) => {
    currentTarget.classList.toggle('is-active');
    currentTarget.closest('.chrome-profile').classList.toggle('is-expanded');
  };

  const burgerMenuRef = useRef();

  const toggleBurgerMenu = () => {
    burgerMenuRef.current.classList.toggle('toggled');
  };

  const profile = user && (
    <div className="chrome-profile">
      <div class="chrome-profile__info">
        <a class="chrome-profile__toggle chrome-top__link chrome-profile__link" onClick={toggleProfileMenu}>
          {user.firstName} {user.lastName}
          <Icon icon="drop-chev" className="chrome-profile__icon" />
        </a>
      </div>

      <div class="chrome-dropdown">
        <div class="chrome-dropdown__section">
          <ul class="chrome-dropdown__list">
            <li class="chrome-dropdown__item">
              <a class="chrome-dropdown__item-link" href="/logout">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div className="chrome-wrap">
      <section className="chrome-top">
        <div className="base">
          <div className="simple-nav-logo" >
            <a className="simple-nav-logo-block" href="https://www.rapid7.com">
              <img src={logo} alt="Rapid7" />
            </a>

            <Link to="/" className="simple-nav-logo-title" title="Extensions">Extensions</Link>
          </div>

          <nav className="chrome-top" role="navigation">
            <div className="burger" onClick={toggleBurgerMenu}>
              <div className="burger__icon">
                <span className="burger__slice" />
                <span className="burger__slice" />
                <span className="burger__slice" />
                <span className="burger__slice" />
              </div>
            </div>

            <div className="chrome-logo__image" />

            <ul className="top-nav">
              <li
                className={`top-nav__elem linkstyle ${path === '/' || path.startsWith('/extension/') ? 'active' : ''}`}
              >
                <Link
                  className="top-nav__btn chrome-top__link"
                  to="/"
                >
                  Library
                </Link>
              </li>

              <li className="top-nav__elem linkstyle">
                <a className="top-nav__btn chrome-top__link" href={properties.endpoints.forum}>
                  Discuss
                </a>
              </li>

              {!profile && (
                <li className="top-nav__elem">
                  <div className="simple-nav-signin">
                    <a id="utilitySignin" href="/login">
                      Sign In
                    </a>
                  </div>
                </li>
              )}
            </ul>

            {profile}
          </nav>
        </div>

        <section ref={burgerMenuRef} className="list-top-nav">
          <ul>
            <li>
              <Link to="/">
                <span className="nav-heading">Library</span>
              </Link>
            </li>

            <li>
              <a href={properties.endpoints.forum}>
                <span className="nav-heading">Discuss</span>
              </a>
            </li>

            {!profile && (
              <li>
                <a href="/login">
                  <span className="nav-heading">Sign In</span>
                </a>
              </li>
            )}
          </ul>
        </section>
      </section>
      <section className="chrome-bottom">
        <section className="chrome-right">
          <section className="app-content">
            {children}
          </section>
        </section>
      </section>
    </div>
  );
};

export default withRouter(Navigation);
