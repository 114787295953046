import React, { Fragment, useCallback, useRef, useContext, useMemo, createContext } from 'react';
import orderBy from 'lodash/orderBy';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string/index';
import styled from 'styled-components';
import { Icon, Label, Tooltip } from "rapid7-ui";
import { useTranslation } from "react-i18next";
import { useFetch } from "js/components/hooks";
import { ExpandableFilter } from "js/components/details/DetailsWrapper";

const FilterWrapper = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  i {
    font-size: 16px;
  }

  hr {
    border: 0.5px solid #C8C8C8;
    margin: 0px;
    margin-top: 10px;
  }

  @media (max-width: 1024px) {
    display: ${({ open }) => open ? 'flex' : 'none'};
    padding: 10px;
    margin-bottom: 20px;
    
    button {
      background-color: white !important;
    }
    
    &.selected {
      background-color: #F4F5F6;
    } 
  }
`;

// const StyledIcon = styled(Icon)`
//   font-size: medium;
// `;
//
// const Rating = styled.div`
//   display: flex;
// `;
//
// const ExtensionSpan = styled.span`
//   display: inherit;
// `;
//
// const Star = ({ number, rating }) =>
//   <StyledIcon icon={`subscribe-star${rating >= number ? '-solid' : ''}`}/>;
//
// const ExtensionRating = ({ rating }) => {
//   return (
//     <Rating>
//       <Star number={1} rating={rating} />
//       <Star number={2} rating={rating} />
//       <Star number={3} rating={rating} />
//       <Star number={4} rating={rating} />
//       <Star number={5} rating={rating} />
//     </Rating>
//   );
// };

const FilterSingle = styled.div`
  padding: 5px;
  font-size: 16px;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;

  .icon {
      color: transparent;
      padding-right: 5px;
      position: relative;
      top: -2px;
    }

  .checkbox {
    position: absolute;
    opacity: 0;
  }

  input[type=checkbox]:checked + label {
    color: #E95F26;

    .icon {
      color: #E95F26;
    }
  }
`;

const FilterComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const FilterSmallComponentWrapper = styled(ExpandableFilter)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const products = [
  {
    "name": "IVM",
    "displayName": "InsightVM"
  },
  {
    "name": "IDR",
    "displayName": "InsightIDR"
  },
  {
    "name": "ICON",
    "displayName": "InsightConnect"
  },
  {
    "name": "AS",
    "displayName": "InsightAppSec"
  }
];

const types = [
  {
    "name": "plugin",
    "displayName": "Plugins",
    "tooltip": "Tools that integrate with InsightConnect to provide specific capabilities for third party solutions."
  },
  {
    "name": "workflow",
    "displayName": "Workflows",
    "tooltip": "A series of actions that execute when a specific event is triggered. Workflows help streamline and automate your security processes.\n"
  },
  {
    "name": "integration",
    "displayName": "Integrations",
    "tooltip": "Solutions that enable interoperability between Insight solution and 3rd party technologies."
  }
];

let filterCheckboxId = 0;

const FilterSingleComponent = ({ name, value, active, onChange, tooltip }) => {
  const onInputChange = useCallback(() => onChange(value), [onChange, value]);

  const id = useMemo(() => `filter-option-${filterCheckboxId++}`, []);

  return (
    <FilterSingle className="flex">
      <input type="checkbox" className="checkbox" value={value} id={id} checked={active} onChange={onInputChange} />
      <Label style={{ "cursor": "pointer", "fontSize": "14px" }} htmlFor={id}><Icon className="icon" icon="table-check"/>{name}</Label>
      {tooltip &&
        <div className="valueHover">
          <Icon icon="information-circled"/>
          <Tooltip className="tooltip" position="bottom">
            {tooltip}
          </Tooltip>
        </div>
      }
    </FilterSingle>
  );
};

const FilteringContext = React.createContext();

const multiselectFilterNames = new Set(['product', 'tags', 'types']);

const FilteringProvider = withRouter((props) => {
  const { children, history, location } = props;

  const currentParameters = useMemo(() => {
    const parameters = queryString.parse(location.search);

    for (const [filterName, value] of Object.entries(parameters)) {
      if (multiselectFilterNames.has(filterName)) {
        const values = value.split(',');

        parameters[filterName] = new Set(values);
      }
    }

    return parameters;
  }, [location.search]);

  const onChangeFilter = useCallback((filterName, value) => {
    const updatedParameters = {
      ...currentParameters
    };

    if (!currentParameters.query && filterName === 'query' && value) {
      // A query was added, switch to relevance
      updatedParameters.sort = 'relevance';
    }

    if (multiselectFilterNames.has(filterName)) {
      const values = new Set(updatedParameters[filterName] || []);

      if (value) {
        values[values.has(value) ? 'delete' : 'add'](value);
      }
      else {
        values.clear();
      }

      updatedParameters[filterName] = values;
    }
    else if (value) {
      updatedParameters[filterName] = value;
    }
    else {
      delete updatedParameters[filterName];
    }

    for (const filterName of multiselectFilterNames) {
      const values = updatedParameters[filterName];

      if (values && values.size > 0) {
        updatedParameters[filterName] = [...values].join(',');
      }
      else {
        delete updatedParameters[filterName];
      }
    }

    const search = queryString.stringify(updatedParameters);

    history.replace(`/?${search}`);
  }, [
    currentParameters
  ]);

  const filtering = {
    parameters: currentParameters,
    onChangeFilter
  };

  return (
    <FilteringContext.Provider value={filtering}>
      {children}
    </FilteringContext.Provider>
  );
});

const FilterComponent = ({
  title,
  options,
  value,
  valueField = "displayName",
  onChange,
  help,
  open
}) => {
  return (
    <Fragment>
      {!open ? (
        <FilterComponentWrapper title={title}>
          {/*<h3 className="card__title" style={{ "fontSize": "18px", "fontWeight": "900" }}>*/}
          {/*  {title}*/}
          {/*</h3>*/}
          <FilterSingleComponent name="All" onChange={onChange} value={undefined} active={!value || value.size === 0}/>
          {orderBy(options, ['displayName'], ['asc']).map((option, i) => (
            <FilterSingleComponent
              key={i}
              name={option.displayName}
              tooltip={option.tooltip}
              value={option[valueField]}
              active={!!(value && value.has(option[valueField]))}
              onChange={onChange}
              help={help}
            />
          ))}
        </FilterComponentWrapper>
      ) : (
        <FilterSmallComponentWrapper title={title}>
          <FilterSingleComponent name="All" onChange={onChange} value={undefined} active={!value || value.size === 0}/>
          {orderBy(options, ['displayName'], ['asc']).map((option, i) => (
            <FilterSingleComponent
              key={i}
              name={option.displayName}
              tooltip={option.tooltip}
              value={option[valueField]}
              active={!!(value && value.has(option[valueField]))}
              onChange={onChange}
              help={help}
            />
          ))}
        </FilterSmallComponentWrapper>
      )}
    </Fragment>
  );
};

const FilterPositioningContext = createContext();
//
// const mediaQuery = window.matchMedia('(max-width: 1024px)');

const FilterPanel = ({ open, onClose }) => {
  const {
    parameters,
    onChangeFilter
  } = useContext(FilteringContext);

  const { t } = useTranslation();

  const wrapperRef = useRef();

  // const filterPositioning = useContext(FilterPositioningContext);
  //
  // const [isSmall, onResize] = useReducer(() => mediaQuery.matches, mediaQuery.matches);

  // useEffect(() => {
  //   if (mediaQuery.addEventListener) {
  //     mediaQuery.addEventListener('change', onResize);
  //   }
  //   else if (mediaQuery.addListener) {
  //     mediaQuery.addListener(onResize);
  //   }
  //
  //   return () => {
  //     if (mediaQuery.removeEventListener) {
  //       mediaQuery.removeEventListener('change', onResize);
  //     }
  //     else if (mediaQuery.removeListener) {
  //       mediaQuery.removeListener(onResize);
  //     }
  //   };
  // }, []);
  //
  // useEffect(() => {
  //   if (open && !isSmall) {
  //     onClose();
  //   }
  //
  //   if (isSmall || !filterPositioning) {
  //     return;
  //   }
  //
  //   const appContent = document.querySelector('.app-content');
  //
  //   const [elementAbove, elementBelow] = filterPositioning;
  //
  //   if (!elementAbove || !elementBelow) {
  //     return;
  //   }
  //
  //   const reset = () => {
  //     Object.assign(wrapperRef.current.style, {
  //       position: null,
  //       top: null,
  //       height: null,
  //       maxWidth: null
  //     });
  //   };
  //
  //   const reposition = () => {
  //     if (!elementAbove.parentElement || !elementBelow.parentElement) {
  //       return;
  //     }
  //
  //     const transform = window.getComputedStyle(elementAbove).transform;
  //
  //     const elementAboveRect = elementAbove.getBoundingClientRect();
  //     const elementBelowRect = elementBelow.getBoundingClientRect();
  //
  //     let top = elementAboveRect.bottom;
  //     const bottom = Math.min(elementBelowRect.bottom, window.innerHeight);
  //
  //     if (transform.startsWith('matrix(')) {
  //       top += parseFloat(transform.split(',').pop());
  //     }
  //
  //     if (wrapperRef.current.scrollHeight > elementBelow.offsetHeight) {
  //       return;
  //     }
  //
  //     Object.assign(wrapperRef.current.style, {
  //       position: 'fixed',
  //       top: `${top}px`,
  //       height: `${bottom - top}px`,
  //       maxWidth: 'calc(25% - 30px)'
  //     });
  //   };
  //
  //   appContent.addEventListener('scroll', reposition);
  //
  //   reposition();
  //
  //   return () => {
  //     reset();
  //
  //     appContent.removeEventListener('scroll', reposition);
  //   };
  // }, [
  //   filterPositioning,
  //   isSmall
  // ]);

  const { data: { results } } = useFetch('/v1/public/tags?first=1000&types=usecase');

  return (
    <FilterWrapper className={`${open ? ' filterMobile selected' : 'filterMobile'}`} open={open} ref={wrapperRef}>
      {/*{open ? <div className="flex" style={{ "padding": "0", "justify-content": "flex-end" }}>*/}
      {/*  <button onClick={onClose}><Icon icon="arrow-chevron-left"/></button></div> : ''}*/}
      <div className="flex" style={{ "alignItems": "center", "justifyContent": "space-between" }}>
        {!open && <h3>Filters</h3>}
        <Link to="/"><button className="btn btn--secondary btn--small">Clear All</button></Link>
      </div>
      <hr/>
      <FilterComponent title={t("Products")} open={open} options={products} value={parameters.product} valueField="name" onChange={(value) => onChangeFilter('product', value)} />
      <hr/>
      <FilterComponent title={t("Type")} open={open} options={types} value={parameters.types} valueField="name" onChange={(value) => onChangeFilter('types', value)} />
      <hr/>
      <FilterComponent title={t("UseCases")} open={open} options={results ? results : []} value={parameters.tags} onChange={(value) => onChangeFilter('tags', value)} />
    </FilterWrapper>
  );
};

export default FilterPanel;

export {
  FilteringContext,
  FilteringProvider,
  FilterPositioningContext
};
