import React, { Fragment } from "react";
import { ExtensionDetail } from 'js/components/details/DetailsWrapper';
import { useFetch, useFetchMD } from "js/components/hooks";

import { Icon } from "rapid7-ui";

const DetailsPage = ({ match }) => {
  const { loading, data } = useFetch(`/v1/public/extensions/${match.params.slugName}${match.params.version ? `/v/${match.params.version.slice(1)}` : ''}`);
  const { loadingMD, dataMD } = useFetchMD(`/v1/public/extensions/${match.params.slugName}/help`);

  if (loading) {
    return <Icon icon="spinner"/>; 
  }
  
  return (
    <Fragment>
      <ExtensionDetail{...data} match={match} markdown={loadingMD ? '' : dataMD}/>
    </Fragment>
  );
};

export default DetailsPage;
