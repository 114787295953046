import React, { Fragment } from "react";
import styled from "styled-components";
import { TagCloud, TagItem, Icon } from "rapid7-ui";
import AppSec from 'img/insightappsec-b-c.png';
import Connect from 'img/insightconnect-b-c.png';
import IDR from 'img/insightidr-b-c.png';
import Ops from 'img/insightops-b-c.png';
import VM from 'img/insightvm-b-c.png';

const CustomTagItem = styled(TagItem)`
  background-color: ${({ color }) => color ? color : "black"};
  font-weight: 700;
  border-radius: 5px 0 !important;
  font-size: 12px !important;
  padding: 0 7px !important;
  border: none !important;
  
  i {
    font-size: 14px;
    margin: 0 5px 1px 0;
    color: white;
  }
  
  span {
    color: white;
  }
`;

const CustomInstallTagItem = styled(TagItem)`
  background-color: transparent;
  font-weight: 500;
  border-radius: 0 5px !important;
  font-size: 12px !important;
  padding: 0 7px !important;
  border: 1px solid #C8C8C8 !important;
  margin: 3px 0 !important;
  
  i {
    font-size: 14px;
    margin: 0 5px 1px 0;
    color: black;
  }
  
  span {
    color: green;
  }
`;

const CustomSupportedButton = styled.button`
  font-weight: 700;
  padding: 0 7px !important;
  border: 1px solid #1CBB98 !important;
  
  i {
    font-size: 14px;
    margin: 0 5px 1px 0;
    color: #F37721;
  }
  
  &:hover {
    background-color: transparent !important;
  }
  
  span {
    color: #1CBB98;
  }
`;

const Line = styled.div`
  margin: 3px 0;
  width: 5px;
  background-color: ${({ color }) => color ? color : "#F37721"};
`;

const StyledIconLabel = styled.span`
  display: flex;
  
  :last-child { hr { display:none; }
`;

const StyledIcon = styled.img`
  width: 95px;
  height: 27px;
  padding: 5px;
`;

const ExtensionType = {
  "plugin": {
    name: "Plugin",
    icon: 'data-collection-plug',
    color: "#F37721"
  },
  "workflow": {
    name: "Workflow",
    icon: "workflows",
    color: "#00B07E"
  },
  "in_product": {
    name: "Integration",
    icon: 'download-cloud',
    color: "#5797B7"
  },
  "integration_listing": {
    name: "Integration",
    icon: 'download-cloud',
    color: "#5797B7"
  },
  "integration_package": {
    name: "Integration",
    icon: 'download-cloud',
    color: "#5797B7"
  }
};

const ExtensionProduct = {
  "InsightAppSec": {
    img: AppSec
  },
  "InsightConnect": {
    img: Connect
  },
  "InsightIDR": {
    img: IDR
  },
  "InsightOps": {
    img: Ops
  },
  "InsightVM": {
    img: VM
  }
};

const DataLabels = ({ value, r7logo, product, listing }) => {
  const type = ExtensionType[value];

  const items = product ?
    (
      value.map((content, i) =>
        <Fragment key={i}>
          <Line/>
          <CustomTagItem type="system">
            <span key={i}>{content.name}</span>
          </CustomTagItem>
        </Fragment>
      )
    ) :
    (
      <Fragment>
        <CustomTagItem className={listing && 'styling'} color={type.color} type="system">
          {r7logo && <Icon icon="small-r7"/>}
          {type && <Icon icon={type.icon}/>}
          {type && <span>{type.name}</span>}
        </CustomTagItem>
      </Fragment>
    );

  if (items.length === 0) {
    return null;
  }

  return (
    <TagCloud size="small">
      {items}
    </TagCloud>
  );
};

const InstallLabels = () => {
  return (
    <Fragment>
      <CustomInstallTagItem type="system">
        {/*<Icon icon={type.icon}/>*/}
        <span>Installed</span>
      </CustomInstallTagItem>
    </Fragment>
  );
};

const SupportedLabels = ({ value }) => {
  return (
    <CustomSupportedButton className="btn btn--secondary">
      {value === "rapid7" && <Icon icon="small-r7"/>}
      <span style={{ "textTransform": "capitalize" }}>{value}</span>
    </CustomSupportedButton>
  );
};

const IconLabels = ({ value, product, details, listing }) => {
  const items = product ?
    (
      value.map((content, i) => {
        const productIcon = ExtensionProduct[content.name];

        return (
          <StyledIconLabel key={i}>
            <StyledIcon src={productIcon.img}/>
            {details && <hr/>}
          </StyledIconLabel>
        );
      }
      )
    ) : "";

  if (items.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {!listing ? (
        <TagCloud size="small">
          {items}
        </TagCloud>
      ) : (
        <TagCloud style={{ "flexDirection": "column" }} size="small">
          {items}
        </TagCloud>
      )}
    </Fragment>
  );
};

export { DataLabels, SupportedLabels, IconLabels, InstallLabels };
