import React, { Fragment, useCallback, useState, useReducer } from 'react';
import DetailsWallpaper from 'img/detail-image.png';
import styled from "styled-components";
import { Icon, Modal } from "rapid7-ui";

const StyledIcon = styled(Icon)`
  font-size: large;
`;

const StyledLightboxIcons = styled.div`
  width: 100px;
  justify-content: center;
  display: flex;
  
  button:focus {
    outline:0;
  }
  
  i {
    font-size: 40px;
  }
`;

const NormalIcons = styled.div`
  width: 20px;

  button:focus {
    outline:0;
  }
  
  button:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
`;

const StyledImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  span {
    display: flex;
    justify-content: center;
  }
`;

const ImageTitle = styled.h3`
  display: flex;
  justify-content: center;
  font-family: Muli;
  color: #4E4E4E;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
`;

const StyledModal = styled(Modal)`
  .modal__frame { 
    max-width: 100% !important;
  }
`;

const useToggle = () => useReducer((value) => !value, false);

const ImageCarousel = ({ slugName, type, version, screenshots }) => {
  const imageSize = 1;
  const maxImageIndex = Math.floor(screenshots.length / imageSize);
  const [toggleView, toggle] = useToggle();

  const [imageIndex, setImageIndex] = useState(0);

  const hasNextImage = imageIndex < maxImageIndex - 1;
  const hasPrevImage = imageIndex > 0;

  const prevImage = useCallback(() => setImageIndex((i) => i - 1), []);
  const nextImage = useCallback(() => setImageIndex((i) => i + 1), []);
  // const currentImage = useCallback(() => setImageIndex((i) => i), []);

  const ImageItems = screenshots
    .slice(imageIndex * imageSize, (imageIndex + 1) * imageSize)
    .map((image) =>
      <StyledImage>
        <ImageTitle>{image.title}</ImageTitle>
        <a onClick={toggle}>
          <img className="slideshowImages" onError={(e) => {
            e.target.onerror = null;
            e.target.src = `${DetailsWallpaper}`;
          }} src={`https://hub-content-integration-prod-1.s3.amazonaws.com/${type}/rapid7/${slugName}/${version}/screenshots/${image.name}`}/>
        </a>
        <span> {imageIndex + 1} / {screenshots.length} </span>
      </StyledImage>
    );

  return (
    <Fragment>
      {screenshots && screenshots.length > 1 ?
        <Fragment>
          {!toggleView ?
            <div className="flex" style={{ "alignItems": "center" }}>
              <NormalIcons>
                {hasPrevImage &&
                <button className="button prevButton" onClick={prevImage}><StyledIcon icon="left-chev"/></button>
                }
              </NormalIcons>
              {ImageItems}

              {/*curentImageIndex */}
              {/*{screenshots.map((i) => <span onClick={currentImage}><Icon key={i} icon="legend-dot"/></span>)}*/}
              <NormalIcons>
                {hasNextImage &&
                <button className="button nextButton" onClick={nextImage}><StyledIcon icon="right-chev"/></button>
                }
              </NormalIcons>
            </div>
            :
            <StyledModal isOpen={toggleView} closeModal={toggle} onClickOutside={toggle}>
              <div className="flex" id="screenshot">
                <StyledLightboxIcons>
                  {hasPrevImage &&
                  <button className="button prevButton" onClick={prevImage}><StyledIcon icon="left-chev"/></button>
                  }
                </StyledLightboxIcons>
                {ImageItems}
                <StyledLightboxIcons>
                  {hasNextImage &&
                  <button className="button nextButton" onClick={nextImage}><StyledIcon icon="right-chev"/></button>
                  }
                </StyledLightboxIcons>
              </div>
            </StyledModal>
          }
        </Fragment>
        :
        <img src={DetailsWallpaper}/>
      }
    </Fragment>
  );
};

export default ImageCarousel;
