import React, { Fragment } from "react";
import { TagCloud, TagItem } from "rapid7-ui";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledTagCloud = styled(TagCloud)`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  a {
    text-decoration: none !important;
    
    &:active {
      text-decoration: underline;
    }
  }
`;

const StyledTagItem = styled(TagItem)`
  border-radius: 3px; 
  background-color: #ececec;
  border: none !important;
  color: #838383;
  padding: 0 5px !important;
  text-decoration: none;
  margin-left: 5px;
  
  // :hover {
  //   text-decoration: underline;
  // }
`;

// const SearchBarTag = ({ tag }) => {
//   const {
//     onChangeFilter
//   } = useContext(FilteringContext);
//
//   const add = useCallback(() => {
//     onChangeFilter('tags', tag);
//   }, [
//     tag,
//     onChangeFilter
//   ]);
//
//   return (
//     <StyledTagItem className="tag-cloud__item tag-cloud__item--system">
//       <button onClick={add}><span>{tag}</span> </button>
//     </StyledTagItem>
//   );
// };

const ExtensionLabels = ({ value, listView }) => {
  return (
    <Fragment>
      <StyledTagCloud className="flex" size="small">
        {value.filter((item) => item.type === 'keyword').slice(0, 3).map((item, i) =>
          <Link key={i} to={`/?tags=${item.displayName}`}>
            <StyledTagItem key={i} className="tag-cloud__item tag-cloud__item--system">{item.displayName}</StyledTagItem>
          </Link>
          // <SearchBarTag tag={item.name} key={item} />
        )}
        {value.type === 'keyword'.length > 3 &&
          <a><StyledTagItem className="tag-cloud__item tag-cloud__item--system">...</StyledTagItem></a>
        }
      </StyledTagCloud>
    </Fragment>
  );
};

export default ExtensionLabels;
