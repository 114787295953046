import {
  useContext,
  useEffect,
  useState
} from 'react';

import { PropertiesContext } from 'js/components/PropertiesProvider';

class FetchError extends Error {
  constructor(code) {
    super(`Bad response: ${code}`);

    this.code = code;
  }
}

const encodeQueryString = (parameters) => {
  return Object.entries(parameters).reduce((encodedParameters, [key, value]) => {
    const encodedValue = Array.isArray(value) ?
      value.map(encodeURIComponent).join(',') :
      encodeURIComponent(value);

    if (value && encodedValue) {
      return [
        ...encodedParameters,
        `${key}=${encodedValue}`
      ];
    }

    return encodedParameters;
  }, []).join('&');
};

function useFetch(url) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const properties = useContext(PropertiesContext);

  useEffect(() => {
    async function fetchUrl() {
      setLoading(true);

      try {
        const response = await fetch(`${new URL(url, properties.endpoints.api)}`, {
          mode: 'cors',
          credentials: 'include'
        });

        if (response.status === 200) {
          const json = await response.json();

          setData(json);
          setLoading(false);
        }
        else {
          throw new FetchError(response.status);
        }
      }
      catch (error) {
        setData(() => {
          throw error;
        });
      }
    }

    fetchUrl();
  }, [url]);

  return { loading, data };
}

function useFetchMD(url) {
  const [dataMD, setDataMD] = useState([]);
  const [loadingMD, setLoadingMD] = useState(true);

  const properties = useContext(PropertiesContext);

  useEffect(() => {
    async function fetchMDUrl() {
      const response = await fetch(`${new URL(url, properties.endpoints.api)}`);

      const json = await response.text();

      setDataMD(json);
      setLoadingMD(false);
    }

    fetchMDUrl();
  }, [url]);

  return { loadingMD, dataMD };
}

export {
  useFetch,
  useFetchMD,
  encodeQueryString
};
