import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import ExtensionLabels from 'js/components/extensions/ExtensionLabels';
import styled from 'styled-components';
import { DataLabels, IconLabels, InstallLabels } from 'js/components/Labels';
import backupLogo from 'img/icon-placeholder-image.png';

const ImageContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 85px;
  width: 85px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  
  img { 
    max-height: 100%;
  }
`;

const ImageContentList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 70px;
  width: 70px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  
  img { 
    max-height: 100%;
  }
`;

const ExtensionContentTitle = styled.span`
  color: #3B454A;
  font-size: 18px;
`;

const ExtensionContentVendorTitle = styled.span`
  padding-bottom: 5px !important;
  font-weight: 900;
  font-size: 11px;
`;

const ExtensionContentDeveloper = styled.div`
  display: flex;
  color: #909BA7;
  font-size: 16px;
`;

const ExtensionDescription = styled.div`
  height: 55px;
  font-size: 12px;
  margin-bottom: 5px;
  color: #4E4E4E;
  line-height: 18px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
`;

const ExtensionDescriptionListing = styled.div`
  height: 36px;
  font-size: 12px;
  margin-bottom: 5px;
  color: #4E4E4E;
  line-height: 18px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media screen and (max-width: 768px){
    max-width: 250px;
  }
`;

const DataLabelStyle = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -3px;
  width: 100%;
  justify-content: space-between;
`;

const StyledProducts = styled.div`
  background-color: #EBEBEB;
  border-top: 1px solid #C8C8C8;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 2px 0;
`;

const ExtensionListItem = ({
  name,
  tags,
  description,
  user,
  products,
  type,
  icon,
  slugName,
  vendor,
  listView,
  installed
}) => {
  return (
    <Fragment>
      {!listView ? (
        <div className="col-xs-12 col-md-6 col-lg-4">
          <section className="card-container">
            <div style={{ "cursor": "default" }} className="card noAfter">
              <section style={{ "marginBottom": "30px" }}>
                <Link to={`/extension/${slugName}`} style={{ "textDecoration": "none", "cursor": "cursor" }}>
                  <div className="ImageContainer" style={{ "marginTop": "20px" }}>
                    <ImageContent>
                      <img alt="Image Unavailable" onError={(e) => {
                        e.target.src = `${backupLogo}`;
                      }} src={icon}/>
                    </ImageContent>
                  </div>
                  <div className="card__content">
                    <ExtensionContentTitle className="card__product-heading">{name}</ExtensionContentTitle>
                    <ExtensionContentVendorTitle className="card__heading">{vendor.toUpperCase()}</ExtensionContentVendorTitle>
                    {user && (<ExtensionContentDeveloper>{user.firstName} {user.lastName}</ExtensionContentDeveloper>)}
                    <ExtensionDescription list={true}>{description}</ExtensionDescription>
                    {tags && (<ExtensionLabels value={tags}/>)}
                  </div>
                </Link>
              </section>
              <StyledProducts>
                <IconLabels product={true} value={products}/>
              </StyledProducts>
            </div>
            <DataLabelStyle>
              <DataLabels listing={true} extensionType={true} value={type}/>
              {installed && <InstallLabels/>}
            </DataLabelStyle>
          </section>
        </div>
      ) : (
        <div className="col-xs-12 col-md-12 col-lg-12">
          <section className="card-container">
            <div style={{ "cursor": "default" }} className="card noAfter">
              <section>
                <Link to={`/extension/${slugName}`} className="flex" style={{ "textDecoration": "none", "cursor": "cursor" }}>
                  <div className="ImageListContainer" style={{ "marginTop": "35px" }}>
                    <ImageContentList>
                      <img alt="Image Unavailable" onError={(e) => {
                        e.target.src = `${backupLogo}`;
                      }} src={icon}/>
                    </ImageContentList>
                  </div>
                  <div className="card__content">
                    <ExtensionContentTitle className="card__product-heading">{name}</ExtensionContentTitle>
                    <ExtensionContentVendorTitle className="card__heading">{vendor.toUpperCase()}</ExtensionContentVendorTitle>
                    {user && (<ExtensionContentDeveloper>{user.firstName} {user.lastName}</ExtensionContentDeveloper>)}
                    <ExtensionDescriptionListing>{description}</ExtensionDescriptionListing>
                    {tags && (<ExtensionLabels value={tags}/>)}
                  </div>
                </Link>
              </section>
              <StyledProducts>
                <IconLabels product={true} value={products}/>
              </StyledProducts>
            </div>
            <DataLabelStyle>
              <DataLabels listing={true} extensionType={true} value={type}/>
              {installed && <InstallLabels/>}
            </DataLabelStyle>
          </section>
        </div>
      )}
    </Fragment>
  );
};

export {
  ExtensionListItem
};
