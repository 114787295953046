import { getProperties } from 'js/components/PropertiesProvider';

const { gtm } = getProperties();

if (gtm && gtm.containerId) {
  const gtag = (...args) => (window.dataLayer || []).push(args);

  gtag('js', new Date());
  gtag('config', gtm.containerId, { anonymize_ip: true });
}
