import React, { Fragment, useState } from 'react';

const geoip2 = window.geoip2;

const r7createCookie = (name, value, days, domain) => {
  let date = new Date();

  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  let expires = `expires=${date.toGMTString()}`;

  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${domain};`;

  // console.log('cookie created'); // eslint-disable-line
};

const r7readCookie = (name) => {
  let nameEQ = `${name}=`;

  let ca = document.cookie.split(';');

  let numCookies = ca.length;

  for (let i = 0;i < numCookies ;i++) {
    let c = ca[i].trimStart();

    if (c.indexOf(nameEQ) >= 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

// Detect the country
const detectCountry = (callback) => {
  let onSuccess = (location) => {
    let thisCountryIso = location.country.iso_code;

    callback(thisCountryIso);
  };

  let onError = (error) => {
    console.log(error); // eslint-disable-line
    callback();
  };

  return geoip2.country(onSuccess, onError);
};

const checkGdprCompliance = (userCountryIso, currentDomain) => {
  //console.log(`this country is ${userCountryIso}`);

  // check if cookie exists
  if (r7readCookie("r7gdprAll") !== null) {
    // console.log(`r7gdprAll cookie detected - value: ${r7readCookie("r7gdprAll")}`);
    // console.log(`r7gdprAnalytics cookie detected - value: ${r7readCookie("r7gdprAnalytics")}`);

    return false;
  }
  else if (userCountryIso === "CA" || userCountryIso === 'US') {
    r7createCookie("r7gdprAll", `notRequired ${userCountryIso}`, 365, currentDomain);
    r7createCookie("r7gdprAnalytics", `notRequired ${userCountryIso}`, 365, currentDomain);

    return false;
  }

  return true;
};

const GDPRComponent = () => {
  const [showGDPR, setshowGDPR] = useState(document.domain !== 'localhost');

  const cookieDomain = (document.domain.match(/\.[^.]+\.[^.]+$/) || [])[0];

  let onClose = () => {
    r7createCookie("r7gdprAll", "accepted", 365, cookieDomain);
    r7createCookie("r7gdprAnalytics", "acknowledged", 365, cookieDomain);

    if (showGDPR === true) {
      setshowGDPR(false);
    }
  };

  //let userCountryISO;
  if (typeof (geoip2) === 'undefined') {
    // console.log('gi0');
    //if IP lookup fails, assume to be Germany for maximum GDPR compliance
    if (showGDPR !== checkGdprCompliance("DE", cookieDomain)) {
      setshowGDPR(checkGdprCompliance("DE", cookieDomain));
    }
  }
  else {
    detectCountry((detectedCountryIso) => {
      // GDPR check
      setshowGDPR(checkGdprCompliance(detectedCountryIso, cookieDomain));
    });
  }

  return (
    <Fragment>
      {showGDPR && (
        <div id="gdprAlert" className="overlay">
          <div className="gdpr-modal">
            <div>
              <h2>Quick Cookie Notification</h2>
              <p>
                This site uses cookies, including for analytics, personalization, and advertising purposes.
                For more information or to change your cookie settings,&nbsp;
                <a href="https://www.rapid7.com/privacy-policy/tracking-technologies/" target="_blank">click here</a>.
                <br/>
                <br/>
                If you continue to browse this site without changing your cookie settings, you agree to this use.
              </p>
              <button className="button mdBtn blue" id="gdprClose" onClick={onClose}>I AGREE, LET’S GO!</button>
              <hr/>
              <p className="fineprint">
                View <a href="https://www.rapid7.com/privacy-policy/tracking-technologies/" target="_blank">Cookie Policy</a> for full details
              </p>
            </div>

          </div>
        </div>
      )}
    </Fragment>
  );
};

export default GDPRComponent;
