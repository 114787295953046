import React, {
  useEffect,
  useReducer,
  createContext
} from 'react';

import once from 'lodash/once';

const UserContext = createContext();

const sessionPingInterval = 60 * 1000;

const sessionPing = () => window.fetch('/ping', { credentials: 'include' });

const getUser = once(() => {
  const userMeta = document.querySelector('meta[name="user"]');

  if (userMeta) {
    return JSON.parse(userMeta.getAttribute('content'));
  }
});

const UserProvider = ({ children }) => {
  const [user, handlePing] = useReducer((user, pingResponse) => {
    if (pingResponse.status === 401) {
      return undefined;
    }

    return user;
  }, undefined, getUser);

  useEffect(() => {
    if (user) {
      const pingInterval = setInterval(async () => handlePing(await sessionPing()), sessionPingInterval);

      return () => clearInterval(pingInterval);
    }
  }, [user]);

  return <UserContext.Provider value={user} children={children}/>;
};

export {
  UserContext,
  UserProvider,

  getUser
};
