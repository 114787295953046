import React, {
  useContext,
  useCallback
} from 'react';

import { Icon } from 'rapid7-ui';

import {
  ProductContext,
  ProductCallbacksContext
} from 'js/components/ProductProvider';

const useExternalLink = (url) => {
  const product = useContext(ProductContext);
  const productCallbacks = useContext(ProductCallbacksContext);

  const onNavigateExternal = useCallback((event) => {
    if (product && window.parent !== window) {
      productCallbacks.navigateExternal(url || event.target.href);
      event.preventDefault();
    }
  }, [
    url,
    product,
    productCallbacks
  ]);

  return onNavigateExternal;
};

const ExternalLink = (props) => {
  return (
    <span>
      <a {...props} target="_blank" rel="nofollow noopener noreferrer" onClick={useExternalLink()} />
      {' '}
      <Icon icon="open-newtab" />
    </span>
  );
};

export default ExternalLink;

export {
  useExternalLink
};
