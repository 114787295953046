import React, {
  useCallback
} from 'react';

import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Icon } from 'rapid7-ui';

const BackButton = styled(withRouter(({ history, className, text }) => (
  <button
    className={className}
    onClick={useCallback(() => {
      if (history.length > 1) {
        history.goBack();
      }
      else {
        history.push('/');
      }
    }, [history])}
  >
    {text ? '' : <Icon icon="left-chev"/> }
    {text ? text : useTranslation().t('Back')}
  </button>
)))`
  color: ${(text) => text ? '#3B454A' : 'black'};
  cursor: pointer;
  padding: ${(text) => text ? '' : '20px 0 0 30px'};
  font-size: ${(text) => text ? '' : '16px'};
  padding-top: 3px;

  &:focus {
    outline: 0;
  }
`;

export default BackButton;
