import {
  useEffect,
  useContext
} from 'react';

import { withRouter } from 'react-router-dom';

import { UserContext } from 'js/components/UserProvider';
import { ProductContext } from 'js/components/ProductProvider';
import { PropertiesContext } from 'js/components/PropertiesProvider';

const Pendo = withRouter(({ history }) => {
  const user = useContext(UserContext);
  const product = useContext(ProductContext);
  const properties = useContext(PropertiesContext);

  useEffect(() => {
    if (window.pendo && properties.pendo) {
      const visitor = user ? {
        id: user.uuid,
        email: user.login,
        firstName: user.firstName,
        lastName: user.lastName
      } : undefined;

      const account = product && product.organization ? {
        id: product.organization.id,
        name: product.organization.name
      } : undefined;

      window.pendo.initialize({
        apiKey: properties.pendo.apiKey,
        visitor,
        account
      });
    }
  }, []);

  useEffect(() => {
    return history.listen(() => {
      if (window.pendo && window.pendo.pageLoad) {
        window.pendo.pageLoad();
      }
    });
  }, [history]);

  return null;
});

export default Pendo;
