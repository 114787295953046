import React from 'react';

import dayjs from 'dayjs';

const FormattedDate = ({ date }) => (
  <time dateTime={date}>
    {dayjs(date).format('MM/DD/YYYY')}
  </time>
);

const FormattedShortDate = ({ date }) => (
  <time dateTime={date}>
    {dayjs(date).format('MMM/YYYY')}
  </time>
);

export { FormattedDate, FormattedShortDate };
