import React, { useContext } from 'react';
import styled from 'styled-components';
import image from 'img/Community.png';
import { PropertiesContext } from "js/components/PropertiesProvider";
import { Link } from "react-router-dom";

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px;
`;

const MainTitle = styled.h1`
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 20px;
    line-height: 35px;
`;

const MainContent = styled.p`
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 20px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px;
`;

const SectionSubTitle = styled.span`
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 26px;
    color: #e95f26;
    min-height: 26px;
`;

const SectionTitle = styled.h3`
    font-size: 21px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 10px;
`;

const SectionContent = styled.p`
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 20px;
`;

const SectionButton = styled.button`
    width: 100%;
    text-decoration: none;
`;

const WelcomeMessage = () => {
  const properties = useContext(PropertiesContext);

  return (
    <div style={{ "height": "100%" }}>
      <section className="content-block featured-content" style={{ "height": "100%" }}>
        <section className="base" style={{ "margin-bottom": "50px" }}>
          <div className="col-xs-12 col-md-12 col-lg-12">
            <TopContainer>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <img src={image}/>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <MainTitle>Welcome to the Rapid7 Extension Library</MainTitle>
                <MainContent>Enhance your Insight products with an expanding library
                  of plugins, workflows and integrations directly from the Insight platform
                </MainContent>
                <Link to="/">
                  <button className="btn btn--primary btn--large" >Take me to the Extension Library</button>
                </Link>
              </div>
            </TopContainer>
          </div>
        </section>
        <section className="base">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <BottomContainer>
              <div className="col-xs-12 col-md-4 col-lg-4">
                <div style={{ "padding-top": "26px" }}>
                  <SectionTitle>Discuss</SectionTitle>
                  <SectionContent>Engage with the growing community in our discussion forum
                    for extension FAQs, best practices and use case ideas.</SectionContent>
                  <a target="_blank" href={properties.endpoints.forum}>
                    <SectionButton className="btn btn--primary btn--large">
                      Take me to the Discussion Forum
                    </SectionButton>
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 col-lg-4">
                <div>
                  <SectionSubTitle>Coming Soon</SectionSubTitle>
                  <SectionTitle>Build</SectionTitle>
                  <SectionContent>Learn how to build your own extensions with comprehensive documentation
                    as well as APIs, SDKs and code examples.</SectionContent>
                  <SectionButton className="btn btn--secondary btn--large is-disabled">Coming Soon</SectionButton>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 col-lg-4">
                <div>
                  <SectionSubTitle>Coming Soon</SectionSubTitle>
                  <SectionTitle>Contribute</SectionTitle>
                  <SectionContent>
                    Share your work with fellow security practitioners by publishing it to our Extension Library.
                  </SectionContent>
                  <SectionButton className="btn btn--secondary btn--large is-disabled" style={{ "margin-top": "25px" }}>Coming Soon</SectionButton>
                </div>
              </div>
            </BottomContainer>
          </div>
        </section>
      </section>
    </div>
  );
};


export default WelcomeMessage;
