import React, { Fragment, useCallback, useContext, useEffect, useRef, useState, useReducer } from "react";
import { Icon, Button, ButtonDropDown, ButtonDropDownItem, ButtonIcon } from "rapid7-ui";
import styled from "styled-components";
import { ExtensionListItem } from 'js/components/extensions/ExtensionListItem';
import Filter, { FilteringContext } from 'js/components/filters/FilterPanel';
import { useFetch, encodeQueryString } from 'js/components/hooks';
import { ProductContext } from "js/components/ProductProvider";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';

const ActionBar = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 40px;
    font-size: 16px;

    i { font-size: 20px;}
    button:focus { outline:0;}
    
    p {
      margin: 0 10px 0 5px;
    }

    div.viewAs {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      
      @media screen and (max-width: 1024px){
        justify-content: space-between;
      }
      
      .sortBy {
        width: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      p {
        font-size: 14px;
        color: #4E4E4E;
      }
      
      .listViewPicker {
        display: flex;
        align-items: baseline;
      
        @media screen and (max-width: 600px){
          display: none;
        }
      }

      button {
        &:hover {
          color: #E95F26;
        }

        &.active {
          color: #E95F26;
        }
      }
    }

    @media (min-width: 1023px) {
      .filter-label { display: none;}
    }

    @media (max-width: 1023px) {
      justify-content: space-between;
      margin-bottom: 10px;
      
      .filter-label {
        align-items: center;
        margin: 4px 0 -10px 0;
        padding-bottom: 10px;
      
        &.selected {
          background-color: #F4F5F6;
        }
      }
    }

    button:focus { outline:0;}

      i { font-size: 20px; }
`;

const ActionBarProduct = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    div.viewAs {
      display: flex;
      width: 300px;
      justify-content: space-around;

      p {
        font-size: 14px;
        color: #4E4E4E;
        display: flex;
        align-items: center;
      }

      button {
        &:hover {
          color: #E95F26;
        }

        &.active {
          color: #E95F26;
        }
      }

      button:focus { outline:0;}
    }

    @media (min-width: 1023px) {
      padding-left: 40%;

      .filter-label { display: none;}
    }

    @media (max-width: 1023px) {
      justify-content: space-between;
    }

     i { font-size: 20px; }
`;

const LoadMoreButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;

    button:hover {
      background-color: #E5E5E5;
    }
`;

const LoadingState = styled.div`
  ._loading_overlay_overlay {
    background-color: rgba(255, 255, 255, 0.3);
  }

  ._loading_overlay_content {
    color: black;
    font-weight: 900;
    font-size: 20px;
  }

  ._loading_overlay_spinner {
    svg circle {
      stroke: black;
    }
  }
`;

const StyledListHeader = styled.h1`
  fontWeight: 900;
  fontSize: 32px;
  color: #3B454A;
  
  @media screen and (max-width: 600px){
    font-size: 20px;
    line-height: 15px;
  }
  
`;

const ActionButtonParent = styled(Button).attrs({
  isDropDown: true,
  context: 'secondary'
})``;

const ButtonDropDownIcon = styled(ButtonIcon).attrs({
  icon: 'drop-chev'
})``;

const useToggle = () => useReducer((value) => !value, false);
const useDropdown = () => useReducer((value) => !value, false);

const ExtensionListWrapper = ({ contentRef }) => {
  const loadMore = useRef();

  const {
    parameters,
    parameters: {
      query,
      product,
      tags,
      types,

      sort = 'alphabetical',
      view = window.innerWidth < 700 ? 'list' : 'grid'
    },
    onChangeFilter
  } = useContext(FilteringContext);

  const currentProduct = useContext(ProductContext);
  const { t } = useTranslation();

  const [currentCursor, setCurrentCursor] = useState(undefined);

  const queryString = encodeQueryString({
    sort,
    first: 18,
    after: currentCursor,
    contains: query,
    products: product ? [...product] : undefined,
    tags: tags ? [...tags] : undefined,
    types: types ? [...types] : undefined
  });

  //Url Fetch
  const { loading, data: latestPage } = useFetch(`/v1/public/extensions?${queryString}`);

  //Load More Fetch
  const LoadMore = useCallback(() => {
    setCurrentCursor(latestPage.pageInfo.endCursor);
  }, [latestPage]);

  const [results, updateResults] = useReducer((allResults, { type, data }) => {
    if (type === 'Append' && data) {
      return [...allResults, ...data];
    }
    else if (type === 'Clear') {
      return [];
    }

    return allResults;
  }, []);

  const NextPage = latestPage.pageInfo && latestPage.pageInfo.hasNextPage;

  const listContentRef = useRef();

  const [filterVisible, toggleFilter] = useToggle();

  useEffect(() => setCurrentCursor(undefined), [parameters]);

  useEffect(() => {
    if (!currentCursor) {
      updateResults({ type: 'Clear' });
    }

    updateResults({ type: 'Append', data: latestPage.results });
  }, [latestPage]);

  // Filtering for feature content and normal content
  //const [featuredContent, normalContent] = partition(results, 'promoted');

  // Logged In list Filter by Requirements/Product
  // const ProductFilterContent = groupBy(results, 'requirements');

  // Logged in list using normal content and logged out with the filtered content
  const LoggedOutList = results.map((content) =>
    <ExtensionListItem listView={view === 'list'} loggedIn={false} key={content.id} {...content} />
  );

  //This will be passed in as a customisable prop and also will be used for the main platform home
  // const LoggedInList = Object.entries(ProductFilterContent).map(([title, content]) =>
  //   <ExtensionCarousel loggedIn={true} title={title} content={content} />
  // );

  const [isActive, toggle] = useDropdown();

  return (
    <Fragment>
      {!currentProduct ? (
        <section className="base">
          <h3 className="content__header mb-2">{t('Explore Extensions')}</h3>
          <ActionBar>
            <div className="viewAs between-xs end-lg">
              <button className={`${filterVisible ? 'selected' : ''} flex filter-label hide-lg left-xs`} onClick={toggleFilter}>
                <Icon icon="eventlog"/>
                <p style={{ "cursor": "pointer" }}>Filters</p>
              </button>
              <div className="sortBy">
                <p>Sort by</p>
                <ActionButtonParent
                  isActive={isActive}
                  onClick={toggle}
                >
                  <span style={{ "textTransform": "capitalize" }}>{sort === 'alphabetical' ? 'Name' : sort}</span>
                  <ButtonDropDownIcon />
                  <ButtonDropDown isActive={isActive}>
                    <ButtonDropDownItem
                      className="list"
                      style={{ "cursor": "pointer", "fontSize": "14px" }}
                      onClick={() => onChangeFilter('sort', 'alphabetical')}
                    >
                      Name
                    </ButtonDropDownItem>
                    <ButtonDropDownItem
                      className="list"
                      style={{ "cursor": "pointer", "fontSize": "14px" }}
                      onClick={() => onChangeFilter('sort', 'date')}
                    >
                      Date
                    </ButtonDropDownItem>

                    {query && (
                      <ButtonDropDownItem
                        className="list"
                        style={{ "cursor": "pointer", "fontSize": "14px" }}
                        onClick={() => onChangeFilter('sort', 'relevance')}
                      >
                        Relevance
                      </ButtonDropDownItem>
                    )}
                  </ButtonDropDown>
                </ActionButtonParent>
                <div className="listViewPicker">
                  <p>View as</p>
                  <button className={`list ${view === 'grid' ? 'active' : ''}`} style={{ "cursor": "pointer" }} onClick={() => onChangeFilter('view', 'grid')}>
                    <Icon icon="dashboard"/>
                  </button>
                  <button className={`list ${view === 'list' ? 'active' : ''}`} style={{ "cursor": "pointer" }} onClick={() => onChangeFilter('view', 'list')}>
                    <Icon icon="list"/>
                  </button>
                </div>
              </div>
            </div>
          </ActionBar>
          <div className="row">
            <div className="col-xs-12 col-md-12 col-lg-3">
              <Filter open={filterVisible} onClose={toggleFilter}/>
            </div>
            <div className="col-xs-12 col-md-12 col-lg-9">
              <LoadingState ref={listContentRef}>
                <LoadingOverlay
                  active={loading}
                  spinner
                  text={t('Loading content')}
                >
                  <section className="cards">
                    <div className="row" ref={contentRef}>
                      {LoggedOutList}

                      {!loading && results.length < 1 && (
                        <div className="null-state">
                          <div className="null-state-container">
                            <div id="graphic" className="null-state__graphic null-state__graphic-"/>
                            <div className="null-state__content">
                              <h3 className="null-state__header">{t('ErrorNoData')}</h3>
                            </div>
                          </div>
                        </div>
                      )}

                      {!loading && NextPage && (
                        <section className="base">
                          <LoadMoreButton>
                            <button ref={loadMore}
                              style={{ "width": "97%", "border": "1px solid #006EC5", "color": "#006EC5" }}
                              className="btn btn-primary" onClick={LoadMore}>
                              {t('Load More')}
                            </button>
                          </LoadMoreButton>
                        </section>
                      )}
                    </div>
                  </section>
                </LoadingOverlay>
              </LoadingState>
            </div>
          </div>
        </section>
      ) : ''}

      {currentProduct ?
        <section>
          <ActionBarProduct>
            <div className="flex filter-label">
              <button style={{ "cursor": "pointer" }} className="list" onClick={toggleFilter}><Icon icon="eventlog"/></button>
              <p>Filters</p>
            </div>
            <h3 className="content__header">{t('Explore Extensions')}</h3>
            <div className="viewAs">
              <p>Sort by</p>
              <ActionButtonParent
                isActive={isActive}
                onClick={toggle}
              >
                <span style={{ "textTransform": "capitalize" }}>{sort === 'alphabetical' ? 'Name' : sort}</span>
                <ButtonDropDownIcon />
                <ButtonDropDown isActive={isActive}>
                  <ButtonDropDownItem
                    className="list"
                    style={{ "cursor": "pointer", "fontSize": "14px" }}
                    onClick={() => onChangeFilter('sort', 'date')}
                  >
                    Date
                  </ButtonDropDownItem>
                  <ButtonDropDownItem
                    className="list"
                    style={{ "cursor": "pointer", "fontSize": "14px" }}
                    onClick={() => onChangeFilter('sort', 'alphabetical')}
                  >
                    Name
                  </ButtonDropDownItem>
                  <ButtonDropDownItem
                    className="list"
                    style={{ "cursor": "pointer", "fontSize": "14px" }}
                    onClick={() => onChangeFilter('sort', 'relevance')}
                  >
                    Relevance
                  </ButtonDropDownItem>
                </ButtonDropDown>
              </ActionButtonParent>
              <p>View as</p>
              <button className={`list ${view === 'grid' ? 'active' : ''}`} style={{ "cursor": "pointer" }} onClick={() => onChangeFilter('view', 'grid')}>
                <Icon icon="dashboard"/>
              </button>
              <button className={`list ${view === 'list' ? 'active' : ''}`} style={{ "cursor": "pointer" }} onClick={() => onChangeFilter('view', 'list')}>
                <Icon icon="list"/>
              </button>
            </div>
          </ActionBarProduct>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3">
              <Filter open={filterVisible} onClose={toggleFilter}/>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9">
              <LoadingState ref={listContentRef}>
                <LoadingOverlay
                  active={loading}
                  spinner
                  text={t('Loading content')}
                >
                  <section className="cards">
                    <div className="row">
                      <Fragment>
                        {LoggedOutList}

                        {!loading && results.length < 1 && (
                          <div className="null-state">
                            <div className="null-state-container">
                              <div id="graphic" className="null-state__graphic null-state__graphic-"/>
                              <div className="null-state__content">
                                <h3 className="null-state__header">{t('ErrorNoData')}</h3>
                              </div>
                            </div>
                          </div>
                        )}

                        {!loading && NextPage && (
                          <LoadMoreButton className="col-sm-12 col-md-12 col-lg-12">
                            <button ref={loadMore} style={{ "width": "100%", "border": "1px solid #006EC5" }} className="btn btn-primary" onClick={LoadMore}>{t('Load More')}</button>
                          </LoadMoreButton>)}
                      </Fragment>
                    </div>
                  </section>
                </LoadingOverlay>
              </LoadingState>
            </div>
          </div>
        </section> : ''}
    </Fragment>
  );
};

export default ExtensionListWrapper;
