import React from 'react';
import { InProductSearchBar } from "js/components/filters/SearchComponent";
import Rapid7Logolt from 'img/rapid7-logo-black-orange.png';
import styled from 'styled-components';
import { NotificationArea } from 'js/components/Notifications';
import ExternalLink from 'js/components/ExternalLink';
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;

  .inproduct-content {
    .content-block {
      margin: 0 5%;
    }
  }
`;

const Header = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  background-color: #DAE4E7;
  z-index: 2;
  height: 60px;

  i.search {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: black;
    font-size: 20px;
  }

  i.help-icon {
    display: flex;
    align-items: center;
    color: black;
    font-size: 20px;
  }

  span {
    display: flex;
    align-items: center;
    color: black;
  }

  a {
    text-decoration: none;
    color: black !important;
  }

  .discussion {
    display: flex;
    align-items: center;
    border-color: black !important;

    margin-right: 10px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    i {
      display: none;
    }
  }

  .help-icon {
    margin-right: 60px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 148px;
  margin-left: 30px;

  @media only screen and (max-width: 1095px){
    width: 100px;
  }
`;

const Title = styled(Link)`
  font-size: 26px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  vertical-align: middle;
  padding-left: 20px;
  font-family: "Muli", sans-serif;
  line-height: 24px;

   @media only screen and (max-width: 1095px){
    font-size: 20px;
  }
`;

const ModalComponent = ({ children }) => (
  <Wrapper>
    <Header>
      <LogoContainer className="simple-nav-logo" >
        <Link to="/" className="simple-nav-logo-block"><Logo src={Rapid7Logolt} alt="Rapid7" /></Link>
        <Title to="/" className="simple-nav-logo-title" title="Extensions">Extensions</Title>
      </LogoContainer>
      <InProductSearchBar/>
      <button className="btn btn-secondary discussion"><ExternalLink href="https://discuss.rapid7.com/" discussion={true}>Join Our Discussion Forum</ExternalLink></button>
    </Header>

    <NotificationArea />

    <div className="inproduct-content" style={{ "overflow-y": "scroll" }}>
      {children}
    </div>
  </Wrapper>
);

export default ModalComponent;
