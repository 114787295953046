import React, {
  createContext,
  useMemo,
  useContext
} from 'react';

import { Redirect } from 'react-router-dom';

import { UserContext } from 'js/components/UserProvider';

const ProductContext = createContext();
const ProductCallbacksContext = createContext();

const productOrigin = document.referrer ? new URL(document.referrer).origin : undefined;

if (window.parent !== window) {
  // Cancels the fallback for the in-product view
  window.parent.postMessage({ type: 'ready' }, '*');
}

const productCallbacks = {
  navigateProduct(route) {
    window.parent.postMessage({
      type: 'navigate-product',
      route
    }, productOrigin);
  },
  navigateExternal(url) {
    if (window.parent !== window) {
      window.parent.postMessage({
        type: 'navigate-external',
        url
      }, productOrigin);
    }
    else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }
};

const ProductProvider = ({ children }) => {
  const user = useContext(UserContext);

  return (
    <ProductContext.Provider value={useMemo(() => user ? user.product : undefined, [])}>
      <ProductCallbacksContext.Provider value={productCallbacks}>
        {children}
      </ProductCallbacksContext.Provider>
    </ProductContext.Provider>
  );
};

const ProductRedirect = () => {
  const product = useContext(ProductContext);

  if (product && product.code !== 'PANDA') {
    return <Redirect to={`/?product=${product.code}`} />;
  }

  return <Redirect to="/" />;
};


export {
  ProductContext,
  ProductCallbacksContext,
  ProductProvider,
  ProductRedirect
};
