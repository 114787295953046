import React, {
  useContext
} from 'react';

import styled from 'styled-components';

import { useFetch } from 'js/components/hooks';
import { withErrorBoundary } from 'js/pages/ErrorPage';

import { FormattedShortDate } from 'js/components/FormattedDate';
import { PropertiesContext } from 'js/components/PropertiesProvider';
import ExternalLink, { useExternalLink } from 'js/components/ExternalLink';

import emptyBlogPosts from 'img/NoneFound.png';

const ExtensionDiscourseTopicWrapper = styled.li`
  padding: 15px;
  border-bottom: 1px solid #D2D6D8;
  display: flex;
  flex-direction: column;
  background: #F4F5F6;
  
  .header {
    font-size: 16px;
    font-weight: 700;
    padding:  0 10px;
  }
  
  hr {
    width: 100%;
    border-top: 1px solid grey;
  }
`;

const ExtensionDiscourseTopicLink = styled(ExternalLink)`
  font-size: 16px;
  color: black;
  text-decoration: none;
  font-weight: 500;
`;

const ExtensionDiscourseTopicTimes = styled.dl`
  margin: 10px 0 0;
  display: flex;
  flex-direction: row;

  dt, dd {
    display: inline-block;
    margin: 0 15px 0 0;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
  }

  dt {
    font-weight: bold;
  }
`;

const ExtensionDiscourseTopic = ({ topic, post }) => (
  <ExtensionDiscourseTopicWrapper>
    <div className="flex">
      <dt className="header" style={{ "flex-grow": "1" }}>Topic</dt>
      {/*<dt className="header">Replies</dt>*/}
      {/*<dt className="header">View</dt>*/}
      <dt className="header">Activity</dt>
    </div>
    <hr/>

    <ExtensionDiscourseTopicLink href={topic.url}>
      {topic.title}
    </ExtensionDiscourseTopicLink>

    <ExtensionDiscourseTopicTimes>
      <dt><img height={24} width={24} src={post.avatarUrl} /></dt>
      <dd>{post.userDisplayName}</dd>

      <dt>Created</dt>
      <dd style={{ "margin-right": "auto" }}><FormattedShortDate date={topic.createdAt}/></dd>
      {/*<dd style={{ "padding": "0 25px" }}>2</dd>*/}
      {/*<dd style={{ "padding": "0 15px" }}>30</dd>*/}
      <dd><FormattedShortDate date={topic.lastPostedAt}/></dd>
    </ExtensionDiscourseTopicTimes>
  </ExtensionDiscourseTopicWrapper>
);

const NewTopicLink = styled.a.attrs({
  children: 'Start a New Discussion',
  className: 'btn btn--primary btn--medium'
})`
  position: absolute;
  top: -40px;
  right: 0px;
  text-decoration: none;                                                                                                               
`;

const ExtensionDiscourseTopicsWrapper = styled.ol`
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: relative;
`;

const ExtensionDiscourseTopicsEmpty = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 30px;

  ${NewTopicLink} {
    position: initial;
    margin: 30px auto 0;
  }
  
  a {
    display: none;
  }
  
  @media screen and (max-width: 1024px){
    h3 {
      font-size: 18px;
    }
  }
`;

const ExtensionDiscourseTopicsEmptyIllustration = styled.img.attrs({
  src: emptyBlogPosts
})`
  height: 275px;
  margin: 30px auto 0;
  
  @media screen and (max-width: 1024px){
    height: 200px;
  }
`;

const ExtensionDiscourseTopics = ({ slug }) => {
  const { data, loading } = useFetch(`/v1/public/extensions/${slug}/topics`);

  const properties = useContext(PropertiesContext);

  const newTopicLink = (
    <NewTopicLink
      href={`${properties.endpoints.forum}new-topic?tags=${slug}`}
      onClick={useExternalLink()}
    />
  );

  return (
    <ExtensionDiscourseTopicsWrapper>
      {newTopicLink}

      {data && data.length > 0 ? (
        data.map(({ topic, post }) => (
          <ExtensionDiscourseTopic key={topic.slug} topic={topic} post={post} />
        ))
      ) : (!loading &&
        <ExtensionDiscourseTopicsEmpty>
          <h3>There are no discussion posts here yet.</h3>
          <ExtensionDiscourseTopicsEmptyIllustration />
          {newTopicLink}
        </ExtensionDiscourseTopicsEmpty>
      )}
    </ExtensionDiscourseTopicsWrapper>
  );
};

export default withErrorBoundary(ExtensionDiscourseTopics);
