import RemarkReact from 'remark-react';

import ExternalLink from 'js/components/ExternalLink';

const isSectionHeading = (title) => ({ type, depth, children }) => {
  const [child] = children || [];

  return type === 'heading' && depth === 1 && child && child.type === 'text' && child.value === title;
};

const extractSection = (ast, title) => {
  const beginIndex = ast.children.findIndex(isSectionHeading(title));

  if (beginIndex < 0) {
    return;
  }

  const heading = ast.children[beginIndex];

  const endIndex = ast.children.findIndex(({ type, depth }, index) => {
    return index > beginIndex && type === 'heading' && depth <= heading.depth;
  });

  return {
    ...ast,
    children: ast.children.slice(beginIndex + 1, endIndex < 0 ? Infinity : endIndex)
  };
};

const omitSection = (ast, titles) => {
  const extractedSections = titles
    // Find contents of sections for each title
    .map((title) => extractSection(ast, title))
    // Remove undefined values for sections not found
    .filter((section) => !!section)
    // Gather up all child markdown nodes of sections
    .flatMap(({ children }) => children);

  return {
    ...ast,
    // children: ast.children.filter((node) => !extractedSections.includes(node))
    children: ast.children
      .filter((node) => {
        if (extractedSections.includes(node)) {
          // The node was in one of the omitted sections
          return false;
        }

        // Check the node is not a heading for one of the omitted sections
        return !titles
          .some((title) => isSectionHeading(title)(node));
      })
  };
};

const configuration = {
  remarkReactComponents: {
    a: ExternalLink
  }
};

const Markdown = ({ markdown }) => {
  if (markdown) {
    return new RemarkReact(configuration).Compiler(markdown, '');
  }

  return null;
};

export { extractSection, omitSection, Markdown };
