import React, {
  createContext
} from 'react';

import once from 'lodash/once';

const PropertiesContext = createContext();

const getProperties = once(() => {
  const json = document.querySelector('meta[name="properties"]').getAttribute('content');

  return JSON.parse(json);
});

const PropertiesProvider = ({ children }) => {
  return <PropertiesContext.Provider value={getProperties()} children={children} />;
};

export {
  PropertiesContext,
  PropertiesProvider,

  getProperties
};
