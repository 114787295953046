import React, {
  useLayoutEffect,
  useContext,
  useReducer,
  useState,
  useMemo,
  useRef,
  Fragment
} from 'react';

import SearchBar from 'js/components/filters/SearchComponent';
import ExtensionListWrapper from 'js/components/extensions/ExtensionListWrapper';
import { FilterPositioningContext } from 'js/components/filters/FilterPanel';
import { withRouter } from 'react-router-dom';
import { ProductContext } from "js/components/ProductProvider";
import styled from 'styled-components';

import GDPRComponent from "js/components/GDPRComponent";

// const TestHeader = styled.h2`
//   color: ${({ theme }) => theme.LoggedIn ? 'grey' : 'dark-blue'};
// `;

const WelcomeContainer = styled.div`
  h1 {
    margin-bottom: 20px;
    
    @media screen and (min-width: 768px){
      margin-bottom: 0;
    }
  }

  p {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    
    @media screen and (max-width: 768px){
      display: none;
    }
  }

  p:last-child {
    margin-bottom: 20px;
    
    @media screen and (max-width: 768px){
      display: none;
    }
  }
`;

const Homepage = () => {
  const currentProduct = useContext(ProductContext);

  const headingRef = useRef();

  const [stickySearchBar, setStickySearchBar] = useState();
  const [contentRef, setContentRef] = useState();

  const [sticky, onScroll] = useReducer(() => {
    const appContent = document.querySelector('.app-content');

    const headingBottom = headingRef.current.getBoundingClientRect().bottom;
    const contentTop = appContent.getBoundingClientRect().top;

    const distanceScrolled = contentTop - headingBottom - 5;

    if (stickySearchBar) {
      return distanceScrolled > stickySearchBar.offsetHeight;
    }

    return distanceScrolled > 0;
  }, false);

  useLayoutEffect(() => {
    const appContent = document.querySelector('.app-content');

    if (!appContent) {
      return;
    }

    appContent.addEventListener('scroll', onScroll);

    return () => appContent.removeEventListener('scroll', onScroll);
  }, []);

  const filterPositioning = useMemo(() => [stickySearchBar, contentRef], [
    stickySearchBar,
    contentRef
  ]);

  return (
    <div id={!currentProduct ? "content" : "app-content"}>
      {!currentProduct && (
        <Fragment>
          <section className="content-block featured-content" ref={headingRef}>
            <section className="base">
              <WelcomeContainer>
                <h1 className="content__header">The Rapid7 Extension Library</h1>
                <p>Enhance your Insight products with an expanding library,
                  including plugins, workflows, and integrations.</p>
                <p className="last">Plus, you can join our discussion forum to share use cases, content and feedback with a
                  growing community of security practitioners.</p>
              </WelcomeContainer>
              {/*<div className="row cards">*/}
              {/*  {dataContent.map((content, i) => <ExtensionListItem key={i} {...content} />)}*/}
              {/*</div>*/}
              <section className="search-content">
                <SearchBar />
              </section>
            </section>
          </section>
        </Fragment>
      )}

      <section className="content-block explore-content">
        {sticky && <SearchBar sticky ref={setStickySearchBar} />}

        <FilterPositioningContext.Provider value={filterPositioning}>
          <ExtensionListWrapper contentRef={setContentRef} />
        </FilterPositioningContext.Provider>
      </section>
      {!currentProduct && (
        <GDPRComponent/>
      )}
    </div>
  );
};

export default withRouter(Homepage);
