import React, {
  createContext,
  useEffect,
  useContext,
  useReducer
} from 'react';

const TitleContext = createContext();

const Title = ({ children }) => {
  const update = useContext(TitleContext);

  useEffect(() => {
    update({ type: 'push', title: children });

    return () => update({ type: 'pop' });
  }, []);

  return null;
};

const TitleProvider = ({ children }) => {
  const [titles, update] = useReducer((titles, { type, title }) => {
    switch (type) {
    case 'push':
      return [...titles, title];
    case 'pop':
      return titles.slice(0, titles.length - 1);
    }

    return titles;
  }, [document.title]);

  useEffect(() => {
    document.title = titles.join(' - ');
  }, [titles]);

  return (
    <TitleContext.Provider value={update}>
      {children}
    </TitleContext.Provider>
  );
};

export {
  Title,
  TitleProvider
};
