import React, {
  useContext
} from 'react';

import { PropertiesContext } from 'js/components/PropertiesProvider';
import ExternalLink from 'js/components/ExternalLink';

const ForumLink = (props) => {
  const { endpoints } = useContext(PropertiesContext);

  return <ExternalLink {...props} href={endpoints.forum} />;
};

export default ForumLink;
